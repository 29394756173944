﻿/* variables used throughout our scss AND bootstrap variables */


//----------------------------------------------
// COLORS
//----------------------------------------------
$gray-100: #F3F3F3;
$gray-300: #DCDCDC;
$gray-600: #9E9FA1;
$gray-900: #4A4A4A;

$dark-gray: $gray-900;
$icon-gray: $gray-600;
$mid-gray: $gray-300;
$light-gray: $gray-100;

$red: #DA291C;
$dark-red: #AF2218;

$white: #FFF;

$duckegg-blue: #E4F4FD;
$powder-blue: #DEECFD;
$light-blue: #35B2E2;
$blue: #009CDE;
$dark-blue: #025A95;
$link-blue: #0078aa;

$teal: #01A3B0;

$light-yellow: #FCE8B6;
$yellow: #F0B323;

$green: #5BA400;

$light-orange: #fcebd2;
$orange: #DF6D00;

$svg-colors: (     
    "icon-gray": $icon-gray, 
    "dark-gray": $dark-gray, 
    "red": $red, 
    "dark-red": $dark-red, 
    "blue": $blue, 
    "dark-blue": $dark-blue, 
    "link-blue": $link-blue, 
    "duckegg-blue": $duckegg-blue, 
    "green": $green,
    "orange": $orange,
    "teal": $teal,
    "yellow": $yellow,
    "white": $white
    );

//----------------------------------------------
// FILTER COLORS
//----------------------------------------------
$filter-class: '';
$filter-light-gray: invert(100%) sepia(5%) saturate(88%) hue-rotate(108deg) brightness(109%) contrast(91%);
$filter-dark-gray: invert(17%) sepia(57%) saturate(0%) hue-rotate(201deg) brightness(105%) contrast(70%);
$filter-icon-gray: invert(78%) sepia(6%) saturate(48%) hue-rotate(182deg) brightness(82%) contrast(84%);
$filter-mid-gray: invert(26%) sepia(0%) saturate(0%) hue-rotate(36deg) brightness(99%) contrast(87%);

$filter-red: invert(19%) sepia(48%) saturate(6736%) hue-rotate(356deg) brightness(91%) contrast(86%);
$filter-dark-red: invert(17%) sepia(80%) saturate(2657%) hue-rotate(347deg) brightness(92%) contrast(108%);

$filter-white: invert(98%) sepia(100%) saturate(0%) hue-rotate(301deg) brightness(102%) contrast(101%);

$filter-duckegg-blue: invert(90%) sepia(7%) saturate(746%) hue-rotate(183deg) brightness(103%) contrast(104%);
$filter-powder-blue: invert(80%) sepia(14%) saturate(308%) hue-rotate(180deg) brightness(112%) contrast(98%);
$filter-light-blue: invert(70%) sepia(90%) saturate(1920%) hue-rotate(164deg) brightness(90%) contrast(97%);
$filter-blue: invert(41%) sepia(38%) saturate(4547%) hue-rotate(172deg) brightness(101%) contrast(101%);
$filter-dark-blue: invert(18%) sepia(85%) saturate(2810%) hue-rotate(189deg) brightness(93%) contrast(98%);
$filter-link-blue: invert(25%) sepia(91%) saturate(1698%) hue-rotate(175deg) brightness(99%) contrast(101%);

$filter-teal: invert(57%) sepia(73%) saturate(4947%) hue-rotate(154deg) brightness(94%) contrast(99%);

$filter-light-yellow: invert(99%) sepia(37%) saturate(4613%) hue-rotate(298deg) brightness(129%) contrast(98%);
$filter-yellow: invert(61%) sepia(74%) saturate(484%) hue-rotate(3deg) brightness(106%) contrast(88%);

$filter-green: invert(48%) sepia(30%) saturate(4387%) hue-rotate(56deg) brightness(101%) contrast(101%);

$filter-orange: invert(44%) sepia(96%) saturate(2493%) hue-rotate(12deg) brightness(97%) contrast(102%);

$filter-white: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);

// COLOR CLASSES
.light-gray {
    color: $light-gray !important;
}

.dark-gray {
    color: $dark-gray !important;
}

.mid-gray {
    color: $mid-gray !important;
}

.icon-gray {
    color: $icon-gray !important;
}

.red {
    color: $red !important;
}

.dark-red {
    color: $dark-red !important;
}

.white {
    color: $white !important;
}

.duckegg-blue {
    color: $duckegg-blue !important;
}

.powder-blue {
    color: $powder-blue !important;
}

.light-blue{
    color: $light-blue !important;
}

.link-blue{
    color: $link-blue!important;
}

.blue {
    color: $blue !important;
}

.dark-blue {
    color: $dark-blue !important;
}

.teal {
    color: $teal !important;
}

.light-yellow {
    color: $light-yellow !important;
}

.yellow {
    color: $yellow !important;
}

.orange {
    color: $orange !important;
}

.green {
    color: $green !important;
}

// FILTER CLASSES
.filter-light-gray {
    filter: $filter-light-gray;
}

.filter-dark-gray {
    filter: $filter-dark-gray;
}

.filter-icon-gray {
    filter: $filter-icon-gray;
}

.filter-mid-gray {
    filter: $filter-mid-gray;
}

.filter-red {
    filter: $filter-red;
}

.filter-dark-red {
    filter: $filter-dark-red;
}

.filter-white {
    filter: $filter-white;
}

.filter-duckegg-blue {
    filter: $filter-duckegg-blue;
}

.filter-powder-blue {
    filter: $filter-powder-blue;
}
    
.filter-light-blue{
    filter: $filter-light-blue;
}
.filter-link-blue{
    filter: $filter-link-blue;
}
.filter-blue {
    filter: $filter-blue;
}

.filter-dark-blue {
    filter: $filter-dark-blue;
}

.filter-teal {
    filter: $filter-teal;
}

.filter-light-yellow {
    filter: $filter-light-yellow;
}

.filter-yellow {
    filter: $filter-yellow;
}

.filter-green {
    filter: $filter-green;
}


.filter-orange {
    filter: $filter-orange;
}
.filter-white{
    filter: $filter-white;
}

.stroke-dark-blue{
    stroke: $dark-blue;
}