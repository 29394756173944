﻿@import '../vendor/bootstrap/mixins/_breakpoints.scss';


/* custom gutters for each breakpoint */
@mixin responsive-gutter($size, $gutter-width) {
    @include media-breakpoint-up($size) {
        .container {
            padding-right: floor($gutter-width);
            padding-left: ceil($gutter-width);
        }

        .col,
        [class*="col-"] {
            padding-right: floor($gutter-width / 2);
            padding-left: ceil($gutter-width / 2);
        }

        .row {
            margin-right: ceil($gutter-width / -2);
            margin-left: floor($gutter-width / -2);
        }
    }
}

@mixin responsive-container($size, $padding) {
    @include media-breakpoint-up($size) {
        .container {
            padding-right: floor($padding);
            padding-left: ceil($padding);
        }
    }
}

// create function/mixin that accepts element or class and array of sizes to apply to each breakpoint
// alt create utility method to loop through arrays of sizes for each header type

@mixin responsive-text-sizing($element, $sizes) {
    @each $breakpoint, $size in $sizes {
        @include media-breakpoint-up($breakpoint) {
            #{$element} { font-size: $size; }
        }
    }
}

@mixin svg-color-modifier($svg-src, $colors-map, $selected-colors...) {
    @each $color-name in $selected-colors {
        &-#{$color-name} {
            background-image: svg-url-replace-color($svg-src, '#000000', map-get($colors-map, $color-name));
            filter: none;
        }
    }
}


