﻿@import '../variables/_vars-modals.scss';

//----------------------------------------------
// MODALS
//----------------------------------------------
.modal-dialog {
    margin-top: 2rem;
    &.modal-dialog-bottom, &.modal-dialog-centered{
        margin-top:0;
    }
}
.modal-dialog-bottom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    min-height: 100%;
    margin-bottom:0;
    margin-top:0;

    &::before {
        height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
        display: block;
        content: "";
    }
}

//  close button needs to be within
//  modal header to properly function
.modal-header {
    border-bottom: 0;
    margin: 0;
    padding: 0;

    .close {
        padding: 0;
        margin: 10px 0 0;

        &:hover,
        &:focus {
            opacity: inherit !important;
        }
    }
}

.modal-content {
    border: none;
    border-radius:0;

    h2, .h2{
        padding-right:60px;
    }
}

.modal button {
    &.close {
        background-size: 30px 30px;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        left: calc(100% - 55px);
        top:5px;
        position: absolute;

        background-image: url("data:image/svg+xml, #{$ai-btn-close}");
        height: 40px;
        width: 40px;
        filter: $filter-dark-gray;
        opacity: .6;
        z-index: 1000;

        &:focus {
            outline: none;
        }
    }
}

// this is the color of the backdrop in the commp
// .7 opacity in the comp, .8 helps see the close button a little better
// however, neither pass accessibility. Nor does the bootstrap default
.modal-backdrop {
    background-color: $dark-gray;

    &.show {
        opacity: .8;
    }
}

// [cmi: 04/29/21]
// custom class for agency (originally placed in the bootstrap vendor file: _modals.scss)
.agency-modal-sm {
  .modal-dialog {
    max-width: $modal-sm;
  }

  .close span {
    display: none;
  }

  .modal-header {
    padding: $modal-inner-padding;;
    padding-bottom: 0px;
  }

  .modal-footer {
    border-top: none;
    padding-top: 0px;
    padding-left: $modal-inner-padding;
    padding-right: $modal-inner-padding;
    display: block;

    .btn-primary {
      margin-right: 0px;
    }
  }
// white close button for IE
@media all and (min-width: map-get($grid-breakpoints, "sm")) {
    .modal-header{
        button.close{
            background-image: url("data:image/svg+xml, #{$ai-btn-close-white}");
            filter:none;

            &:hover{
                opacity: 0.75 !important;;
            }
        }
    }
}
}

@media all and (min-width: map-get($grid-breakpoints, "sm")) {
    .modal-dialog {
        padding: 0 50px;
        margin-top: 5rem;

        &.modal-dialog-bottom, &.modal-dialog-centered {
            margin: 3rem auto;
            -ms-flex-align: center;
            align-items: center;
            min-height: calc(100% - 6rem);

            &::before {
                height: subtract(100vh, 6rem);
                display: block;
                content: "";
            }
        }
    }

    .modal button.close {
        background-size: 30px 30px;
        left: auto;
        position: absolute;
        right: -35px;
        top: -35px;

        filter: $filter-white;
        opacity: 1;
    }

    .modal-header {
        .close {
            margin: 0;
        }
    }
}

@media all and (min-width: map-get($grid-breakpoints, "lg")){
    .modal button.close{
        background-size: 40px 40px;
        right: -40px;
        top: -40px;
    }
}

@media all and (max-width: 575px) {
    .modal.fade .modal-dialog-bottom {
        transform:translate(0, 50px);
    }
    .modal.fade .modal-dialog-bottom {
        transform: translate(0, 0);
    }
}

.img-modal-toggle {
    cursor: pointer;
}