﻿.swiper-carousel-container {
    max-width: 640px;
    margin: 0;
}

.swiper-slide::before {
    background: none;
}

.swiper-slide {
    background-color: #fff;
    /* position: relative;
        background-position: top left;
        color: #222;*/
}

.swiper-slide-gray-container {
    position: absolute;
    background-color: rgba(216,216,216,0.9);
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
}

.swiper-slide-gray-container .swiper-slide-count {
    display: none;
}

.swiper-slide-caption {
    position: relative;
    margin: 0;
    padding: 0;
    margin-right: 130px;
    border-right: 1px solid #4a4a4a;
}

.swiper-slide-caption .readmore {
    position: absolute;
    top: 30%;
    right: 12px;
}

.swiper-slide-gray-container.expanded .swiper-slide-caption .readmore {
    display: none;
}

.swiper-slide-caption .close-readmore {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
}


.swiper-slide-caption .close-readmore a {
    display: inline-block;
    font-size: 2rem;
}

.swiper-slide-gray-container.expanded .swiper-slide-caption .close-readmore {
    display: block;
}

.caption-text {
    position: relative;
    height: 50px;
    width: 90%;
    padding: 15px 0 0 15px;
    white-space: nowrap;
    color: #4a4a4a;
    overflow: hidden;
    text-overflow: ellipsis;
}

.swiper-slide-gray-container.expanded {
    height: auto;
    max-height: 390px;
}

.swiper-slide-gray-container.expanded .swiper-slide-count {
    display: block;
    margin: 0;
    padding: 0;
    padding-right: 130px;
    border-top: 1px solid #4a4a4a;
}

.swiper-slide-gray-container.expanded .swiper-slide-count .slide-count-text {
    height: 50px;
    padding: 15px 15px 0 15px;
    color: #4a4a4a;
    text-align: right;
    border-right: 1px solid #4a4a4a;
    font-weight: bold;
}

.swiper-slide-gray-container.expanded .swiper-slide-caption {
    position: relative;
    margin: 0;
    padding: 15px;
    margin-right: 0;
    border-right: none;
}


.swiper-slide-gray-container.expanded .caption-text {
    height: auto;
    white-space: normal;
    text-overflow: "";
    width: 100%;
    padding: 15px;
}



.swiper-button-prev {
    display: block;
    // background-image: url("/lib/images/svgs/black-arrow-left.svg");
    bottom: 5px;
    right: 80px;
    top: auto;
    left: auto;
}

.swiper-button-next {
    display: block;
    // background-image: url("/lib/images/svgs/black-arrow-right.svg");
    bottom: 5px;
    right: 20px;
    top: auto;
}

.swiper-slide-count {
    display: inline-block;
    padding: 0;
}


@media only screen and (max-width: 600px) {
    .swiper-slide:not(.swiper-slide-active) {
        opacity: 0 !important;
    }

    .swiper-slide-caption .readmore {
        display: none;
    }

    .swiper-slide-gray-container {
        position: relative;
        margin: 10px 40px 15px 40px;
        background-color: #fff;
        height: auto;
    }

    .swiper-slide-caption {
        position: static;
        margin-right: 0px;
        border-right: none;
    }

    .caption-text {
        color: #000;
        height: auto;
        padding: 0;
        white-space: normal;
        text-overflow: "";
        width: 100%;
    }

    .swiper-button-next {
        // background-image: url("/lib/images/svgs/blue-arrow-right.svg");
        right: 5px;
        bottom: auto;
        top: 85%;
    }

    .swiper-button-prev {
        // background-image: url("/lib/images/svgs/blue-arrow-left.svg");
        top: 85%;
        right: auto;
        left: 5px;
        bottom: auto;
    }
}
