﻿//----------------------------------------------
// BLOCKQUOTES
//----------------------------------------------

// NOTE: Standard block quote styles can be acheived by applying the following classes to an element: h2, and text-lower. For example:
//                          <div class="h2 text-lower"></div>
// We agreed to handle standard block quotes with h2 styling, so after applying text color is needed, the above classes produce all styles you need for it.

.icon-block-quote {
    position: relative;
    min-height: 40px;
    margin-top: 30px;
    display: flex;

    @media all and (min-width: map-get($grid-breakpoints, "lg")) {
        min-height: 80px;
    }

    .ibq-icon-wrapper {
        position: absolute;
        display: flex;
        height: 100%;

        @media all and (min-width: map-get($grid-breakpoints, "md")) {
            align-items: center;
        }

        .ai {
            background-position: center;
            height: 40px;
            margin-top: 4px;
            padding: 0 0 0 40px;
            width: 40px;

            @media all and (min-width: map-get($grid-breakpoints, "lg")) {
                height: 80px;
                margin-top: 0;
                padding: 0 0 0 105px;
                width: 80px;
            }

            &.ibq-icon-reduced {
                height: 31px;

                @media all and (min-width: map-get($grid-breakpoints, "lg")) {
                    height: 58px;
                }
            }
        }
    }

    .ibq-text {
        display: flex;
        align-items: center;
        height: 100%;
        min-height: 40px;
        padding-left: 60px;

        @media all and (min-width: map-get($grid-breakpoints, "lg")) {
            padding: 0 0 0 135px;
            min-height: 80px;
        }
    }

    &.ibq-reduced-space {

        margin-top: 5px;

        .ai {
            height: 31px;

            @media all and (min-width: map-get($grid-breakpoints, "lg")) {
                padding: 0;
                height: 58px;
                width: 60px;
            }

        }

        .ibq-text {
            @media all and (min-width: map-get($grid-breakpoints, "lg")) {
                padding-left: 90px;
            }
        }
    }

    &.ibq-align-start {
        .ibq-icon-wrapper, .ibq-text {
            align-items: baseline;

            @media all and (min-width: map-get($grid-breakpoints, "md")) {
                align-items: baseline;
            }
        }
    }
}