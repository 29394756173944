﻿@import '../abstracts/_mixins.scss';



/* 
    Headers
    Body Copy
    Anchors
*/

// BODY
body, .p {
    font-size: $font-size-base;
    font-weight: normal;
}

@include responsive-text-sizing('body, .p', ('xxs': 1rem, 'sm': 1.125rem, 'md': 1.125rem, 'lg': 1.25rem, 'xl': 1.25rem));


// ANCHORS
a {
    color: $link-blue;
    font-weight: normal;
    transition: all .25s;
    text-decoration: underline;
    text-decoration-color: transparent;

    &:visited {
        color: $dark-blue;
    }

    &:hover {
        color: $link-blue;
        text-decoration: underline;
        text-decoration-color: $link-blue;
    }
}



// HEADINGS
h1, .h1 {
    font-size: 2rem;
    color: $dark-red;
    line-height: 1.0625;
    margin-bottom: 1.75rem;
}

h2, .h2 {
    font-size: 1.5rem;
    color: $dark-red;
    line-height: 1.0625;
    margin-bottom: 1rem;
}

h1, .h1, h2, .h2 {
    text-transform: uppercase;

    &.text-lower {
        text-transform: none;
    }
}

h3, .h3 {
    font-size: 1.375rem;
    font-weight:600;
    color: $dark-gray;
    line-height: 1.25;
    margin-bottom:1.25rem;
}

h4, .h4 {
    font-weight: 600;
    font-size: 1.125rem;
    color: $dark-gray;
    line-height: 1.22;
}

@include responsive-text-sizing('h1, .h1', ('sm': 2rem, 'md': 2rem, 'lg': 2.5rem, 'xl': 2.5rem));
@include responsive-text-sizing('h2, .h2', ('sm': 1.5rem, 'md': 1.5rem, 'lg': 2rem, 'xl': 2rem));
@include responsive-text-sizing('h3, .h3', ('sm': 1.375rem, 'md': 1.375rem, 'lg': 1.5rem, 'xl': 1.75rem));
@include responsive-text-sizing('h4, .h4', ('sm': 1.125rem, 'md': 1.25rem, 'lg': 1.375rem, 'xl': 1.5rem));

@media all and (min-width:map-get($grid-breakpoints, "sm")){
    h3, .h3{
        line-height:1.09;
    }
}

@media all and (min-width:map-get($grid-breakpoints, "md")) {
    h1, .h1 {
        margin-bottom: 1.25rem;
    }

    h2, .h2 {
        margin-bottom: 1.25rem;
        line-height: 1.125;
    }

    h3, .h3 {
        line-height: 1.09;
    }

    h4, .h4 {
        line-height: 1.22;
    }
}

@media all and (min-width:map-get($grid-breakpoints, "lg")) {
    h1, .h1 {
        line-height:1.1;
        margin-bottom: 2.5rem;
    }

    h2, .h2 {
        line-height: 1.06;
        margin-bottom: 1.25rem;
    }

    h3, .h3 {
        line-height: 1.08;
    }

    h4, .h4{
        line-height:1.18;
    }
}

@media all and (min-width:map-get($grid-breakpoints, "xl")) {
    h3, .h3 {
        line-height: 1.14;
    }

    h4, .h4 {
        line-height: 1.16;
    }
}

// UTILITIES FOR TEXT
small, .text-small {
    font-size: .875rem !important
}

@include responsive-text-sizing('small, .text-small, .small', ('lg': 1rem !important));

.text-large {
    font-size: 1.25rem !important;
}

@include responsive-text-sizing('.text-large', ('lg': 1.5rem !important));

.text-lower {
    text-transform: lowercase;
}
.text-transform-none{
    text-transform:none;
}

.text-upper {
    text-transform: uppercase;

    &h3, &.h3, &h4, &.h4 {
        margin-bottom: $headings-uppercase-margin-bottom;
        line-height: 1.13;
    }
}

.text-italic {
    font-style: italic;
}

.no-break {
    white-space: nowrap;
}

.word-break {
    word-break: break-word;
}

.break-all{
    word-break:break-all;
}

// text font weights
.text-weight-light {
    font-weight: 300 !important;
}
.text-normal {
    font-weight: normal !important;
}
.text-bold {
    font-weight: $font-weight-bold !important;
}

.text-bolder {
    font-weight: $font-weight-bolder !important;
}

// text colors
.color-dark-gray {
    color: $dark-gray !important;
}

.color-red {
    color: $red !important;
}
.color-dark-red {
    color: $dark-red !important;
}

.color-light-blue {
    color: $light-blue;
}

.color-dark-blue {
    color: $dark-blue;
}

.color-orange {
    color: $orange;
}


// specific styling for newsletter story pages
.newsletter-story h2{
    margin-top:30px;
}
@media all and (min-width:map-get($grid-breakpoints, "md")) {
    .newsletter-story h2 {
        margin-top: 45px;
    }
}

.line-height-small{
    line-height:1.2;
}
.line-height-normal{
    line-height:1.3;
}
