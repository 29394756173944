﻿.allconnect {
    #test {
        text-align: left !important;
    }

    h2, .logo-aep {
        display: none;
    }

    .text-aep {
        font-size:inherit;
        margin: 0 !important;
        max-width:95%;
        text-align: left;
    }

    .button-aep {
        border: none;
        border-radius: 4px;
        font-size: 1.1875rem; //19px
        line-height: 2; //38px
        padding: 0 20px;
        color: $dark-gray;
        background-color: rgba(255, 255, 255, 0.23);
        border: solid 1px $dark-gray;
        margin: 30px 0;
        width: auto;
        display: inline-block;
        text-decoration: none;

        &:hover {
            background-color: $dark-gray;
            color: $white;
            text-decoration: none;
        }
    }

    @media all and (min-width: map-get($grid-breakpoints, "xl") ) {
        .button-aep {
            font-size: 1.625rem; //26px
            line-height: 2.3077; //60px
            padding: 0 30px;
        }
    }
}
