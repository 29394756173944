﻿
//----------------------------------------------
// Classes for news stories
//----------------------------------------------

.story-title {
    a {
        color: $dark-gray;

    }
    a:hover {
        text-decoration-color: $dark-gray;
    }
}


/*#region story images - classes are in story admin as well*/
figure.aep-story-image {
    width: 100%;
    margin: 0 0 25px 0;
}

figure.aep-story-image::after {
    content: "";
    clear: both;
    display: table;
}

figure.aep-story-image img {
    max-width: 100%;
    height: auto;
    border-radius: .25em;
}

figure.aep-story-image figcaption {
    padding-top: 12px;
    font-size: 94%;
}

figure.aep-story-image.right {
    float: none;
    text-align: left;
    margin: 0 0 25px 0;
}

figure.aep-story-image.center {
    float: none;
    text-align: left;
    margin: 0 auto 25px auto;
}

.aep-story-video {
    margin: 0 0 20px 20px;
}

span.story-primary-heading {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    color: $dark-red;
    text-transform: uppercase;
    margin-bottom: .5em;
}

span.story-secondary-heading {
    display: block;
    font-size: 2rem;
    color: $dark-gray;
    font-weight: normal;
    margin-bottom: .5em;
}

@media all and (min-width: map-get($grid-breakpoints, "lg") ) {
    figure.aep-story-image.medium {
        width: 60%;
    }

    .aep-story-video {
        float: right;
        width: 60%;
        margin: 0 0 20px 20px;
    }
}

@media all and (min-width: map-get($grid-breakpoints, "md") ) {
    figure.aep-story-image.center {
        text-align: center;
    }

    figure.aep-story-image.right {
        float: right;
        width: 60%;
        margin: 0 0 25px 20px;
    }

    figure.aep-story-image.small {
        width: 33%;
    }

    figure.aep-story-image.medium {
        width: 45%;
    }

    .aep-story-video {
        float: right;
        width: 45%;
        margin: 0 0 25px 20px;
    }
}
/*#endregion*/