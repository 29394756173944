﻿//----------------------------------------------
// CONTEXT NAVIGATION
//----------------------------------------------

.context-nav-container {
    .cnc-header {
        font-weight: $font-weight-bolder;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 0;
        color: $dark-blue;
        font-size: 28px;

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            padding: 0 25px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            padding: 0 26px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "xl") ) {
            padding: 0 30px;
        }
    }

    .cnc-header-icon {
        display: inline-block;
        height: 38px;
        width: 36px;

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            height: 48px;
            width: 45px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "xl") ) {
            height: 64px;
            width: 62px;
        }
    }


    .cnc-links-wrapper {
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            padding-left: 71px;
            padding-right: 60px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            padding-left: 84px;
            padding-right: 0;
        }

        @media all and (min-width: map-get($grid-breakpoints, "xl") ) {
            padding-left: 102px;
            padding-right: 306px;
        }
    }

    .cnc-link {
        position: relative;
        width: 100%;
        border-bottom: 1px solid $dark-gray;
        padding: 0;
        text-transform: uppercase;

        &:last-child {
            border-bottom: none;

            @media all and (min-width: map-get($grid-breakpoints, "md") ) {
                border-bottom: 1px solid $dark-gray;
            }
        }

        & > a {
            padding: 15px 10px 15px 47px;
            line-height: 1.2;
            color: $dark-gray;
            font-weight: $font-weight-bold;
            display: block;
            text-decoration: none;

            &:hover {
                text-decoration: none;

                span {
                    text-decoration: underline;
                    text-decoration-color: $dark-gray;
                }
            }

            @media all and (min-width: map-get($grid-breakpoints, "sm") ) {
                padding-right: 15px;
            }

            @media all and (min-width: map-get($grid-breakpoints, "md") ) {
                padding: 15px 0;
            }
        }

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            padding-right: 0;
            margin-right: 15px;
            flex: 0 0 calc(50% - 15px);
            max-width: calc(50% - 15px);
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            margin-right: 30px;
            flex: 0 0 calc(50% - 30px);
            max-width: calc(50% - 30px);
        }

        @media all and (min-width: map-get($grid-breakpoints, "xl") ) {
            margin-right: 30px;
            flex: 0 0 calc(50% - 30px);
            max-width: calc(50% - 30px);
        }
    }
}