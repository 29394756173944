﻿// -----------------------------------------
// SEARCHBAR
// -----------------------------------------

.search-container {
    display: none;
}

@media screen and (min-width: map-get($grid-breakpoints, "md")) {
    nav.aep-navbar.navbar-expand-md {
        /* SEARCH FORM */
        .open-search {
            display: inline-block;
            // background: $white url("/lib/images/svgs/search.svg") no-repeat;
            background-size: 28px 28px;
            background-position: center center;
            width: 55px;
            height: 55px;
            border-radius: 5px;
            cursor: pointer;
        }

        .search-container {
            z-index: 99;
            display: block;
            position: absolute;
            overflow: hidden;
            right: 24px;
            flex-grow: inherit;
            height: 55px;
            background-color: $white;
            border-radius: 5px;
            width: 0px;
            -webkit-transition: width 0.2s; /* Safari */
            transition: width 0.2s;

            .close-search {
                overflow: hidden;
                height: 55px;
                padding-left: 8px;
                padding-right: 3px;

                span {
                    font-size: 4rem;
                    font-weight: $font-weight-light;
                    color: $icon-gray;
                    height: 55px;
                    width: 40px;
                    text-align: center;
                    cursor: pointer;
                    line-height: 58px;
                }
            }

            .search-form {
                &:focus {
                    outline: none;
                }

                .search-input {
                    display: inline-block;
                    height: 35px;
                    width: 340px;
                    font-size: 16px;
                    border: none;
                    border-radius: 0;
                    background-color: $white;
                    border-right: 1px solid $icon-gray;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                .search-icon {
                    display: inline-block;
                    width: 55px;
                    height: 55px;
                    // background: transparent url("/lib/images/svgs/search.svg") no-repeat;
                    background-size: 28px 28px;
                    background-position: center center;
                    cursor: pointer;
                    border: none;

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        button.navbar-toggle {
            display: none;
        }

        &.search-opened {
            .search-container {
                width: 445px;
                border: 1px solid $icon-gray;
            }
            /* It is ok for this to be removed from the DOM when search is opened */
            .open-search {
                display: none;
            }
            /* doing display: none removes elements from the DOM. This method hides elements while still keeping them in the DOM, and accessible to other users */
            .navbar-nav,
            .btn-global-login,
            .aep-icon-login {
                position: absolute;
                left: -9999px;
            }
        }
    }
}

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    nav.aep-navbar.navbar-expand-md {
        .open-search {
            background-size: 32px 32px;
            width: 60px;
            height: 55px;
        }

        .search-container {
            right: 50px;

            .search-form {

                .search-input {
                    font-size: 20px;
                }

                .search-icon {
                    width: 60px;
                    height: 55px;
                    background-size: 32px 32px;
                }
            }
        }
    }
}

@media screen and (min-width: map-get($grid-breakpoints, "xl")) {
    nav.aep-navbar.navbar-expand-md {

        .search-container {
            right: 85px;
        }
    }
}

.off-canvas-overlay {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    display: none;
    background: $dark-gray;
    opacity: 0.5;
}

.nav-overlay {
    display: none;
    position: fixed;
    left: 0;
    width: 100%;
    height: 126px;
    overflow: hidden;
}

.off-canvas-overlay.show-overlay,
.nav-overlay.show-overlay {
    display: block;
}

.close-x {
    display: inline-block;
    cursor: pointer;
    float: right;
    margin: 0 0 0 1rem;
    font-size: 3rem;
}