﻿@import '../variables/_vars-alerts.scss';

//----------------------------------------------
// ALERTS
//----------------------------------------------


.alert-box {
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid $dark-red;
    padding: 15px;
    font-size: 0.875rem;
    line-height: 1.25;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-left: 50px;

    &.br-none {
        border-radius: 0;
    }


    .title {
        font-weight: $font-weight-bolder;
        font-size: 1rem;
        color: $dark-red;
        margin-bottom: 3px;
    }

    .icon {
        background-position: center center;
        background-repeat: no-repeat;
        height: 20px;
        width: 18px;
        left: 17px;
        position: absolute;
        -ms-flex-item-align: baseline;
        align-self: baseline;
    }

    .message {
        font-size: 0.875rem;
    }

    .icon.ai-bolt {
        background-size: 14px 20px;
    }

    .icon.ai-alert-triangle, .icon.ai-sad-face, .icon.ai-clock, .icon.ai-scam {
        background-size: 18px;
    }


    &.bg-orange, &.bg-light-orange, &.bg-red {
        border: none;
    }

    &.bg-orange {
        background-color: $orange;

        *, .title {
            color: $white;

            &:hover {
                text-decoration-color: $white;
            }
        }

        a {
            text-decoration-color: $white;
        }
    }

    &.bg-light-orange {
        background-color: $light-orange;

        .title {
            color: $orange;
        }
     
    }

    &.bg-red {
        background-color: $dark-red;


        *, .title {
            color: $white;

            &:hover {
                text-decoration-color: $white;
            }
        }

        a {
            text-decoration-color: $white;
        }
    }

    &.border-blue {
        border-color: $dark-blue;

        .title {
            color: $dark-blue;
        }
    }

    &.border-orange {
        border-color: orange;

        .title {
            color: $orange;
        }
    }

    &.border-green {
        border-color: $green;

        .title {
            color: $green;
        }
    }

    &.border-gray {
        border-color: $dark-gray;

        .title {
            color: $dark-gray;
        }
    }

    &.bg-striped {
        background-repeat: no-repeat;
        background-position: -480px;
        // background-image: url("/lib/images/svgs/background-stripes.svg");

        &.bg-light-orange {
            // background-image: url('/lib/images/svgs/background-stripes2.svg');
        }
    }



    &.stacked {
        flex-wrap: wrap;
        align-items: flex-start;
    }

    @media all and (min-width:map-get($grid-breakpoints, "sm")) {
        .icon {
            height: 22px;
        }

        .title {
            font-size: 1.125rem;
        }

        .message {
        }

        &.bg-striped {
            /*background-position: -390px 0%;*/
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "md")) {
        min-height: 70px;
        flex-wrap: nowrap;
        /*align-items: center;*/
        padding: 15px 30px;
        padding-left: 70px;


        .title {
            font-size: 1.25rem;
            margin-bottom: 0;
            width: 45%;
            -ms-flex-item-align: center;
            align-self: center;
        }

        .icon {
            height: 25px;
            width: 24px;
            left: 30px;
        }

        .icon.ai-alert-triangle, .icon.ai-sad-face, .icon.ai-clock, .icon.ai-scam {
            background-size: 24px;
        }

        .icon.ai-bolt {
            background-size: 16px 22px;
        }


        .message {
            width: 55%;
            padding-left: 30px;
            font-size: 1rem;
            -ms-flex-item-align: center;
            align-self: center;
        }

        &.bg-striped {
            background-position: -320px 0%;
        }

        &.stacked {
            .message {
                margin-left: 0;
                padding-left: 0;
                width: 100%;
            }

            .title {
                width: 100%;
            }
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "lg")) {
        min-height: 74px;
        padding-left: 94px;

        .title {
            padding-left: 2px;
            font-size: 1.5rem;
        }

        .message {
            font-size: 1.125rem;
        }

        &.stacked .message {
            width: 100%;
            padding-left: 2px;
        }

        &.bg-striped {
            background-position: -250px 0;
        }

        .icon {
            width: 35px;
            height: 35px;
            left: 40px;
            margin-top: -3px;
        }

        .icon.ai-alert-triangle, .icon.ai-sad-face, .icon.ai-clock, .icon.ai-scam {
            background-size: 35px;
        }

        .icon.ai-bolt {
            background-size: 23px 33px;
        }

        &.small-sized {
            padding-left: 70px;

            .title {
                font-size: 1.25rem;
            }

            .message {
                font-size: 1.125rem;
            }

            .icon {
                left: 24px;
                height: 28px;
                width: 26px;
                margin-top: -2px;
            }

            .icon.ai-alert-triangle, .icon.ai-sad-face, .icon.ai-clock, .icon.ai-scam {
                background-size: 26px;
            }

            .icon.ai-bolt {
                background-size: 20px 28px;
            }

            &.bg-striped {
                background-position: -200px 0;
            }
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "xl")) {
        min-height: 80px;

        .title {
            width: 40%;
        }

        .message {
            width: 60%;
            font-size: 1.25rem;
        }

        &.stacked .message {
            width: 100%;
        }

        &.bg-light-orange, &.bg-orange, &.bg-red {
            background-position: -190px 0;
        }

        &.small-sized {
            &.bg-striped {
                background-position: -200px;
            }
        }
    }
}

.issue {
    border-bottom: solid $red 1px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    h3 {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 25px;
        padding-left: 40px;
    }

    .ai-warning-circle {
        position: absolute;
        left: 0;
        top: 0;
        width: 25px;
        height: 25px;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    &.no-border {
        border: none;
        padding-bottom: 0;
    }


    @include media-breakpoint-up("md") {
        margin-bottom: 40px;
    }

    @include media-breakpoint-up("lg") {
        margin-bottom: 60px;

        h3 {
            min-height: 30px;
            padding-left: 45px;
        }

        .ai-warning-circle {
            width: 30px;
            height: 30px;
        }
    }
}
