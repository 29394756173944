﻿//----------------------------------------------
// TABS
//----------------------------------------------

.search-tabs {
    ul.nav-tabs {
        display: flex;
        gap: 4px;
        li.nav-item {
            margin: 0 !important;
            flex: 1;
            border: 0 !important;
        }
        button.nav-link {
            width: 100% !important;
        }
    }
}
.nav-tabs {
    border-bottom: 0;

    button.nav-link:focus {
        outline: none;
    }
    .nav-item {
        border-radius: 5px 5px 0 0;
        border-right: 5px solid $duckegg-blue;
        border-left: 5px solid $duckegg-blue;
        border-top: 5px solid $duckegg-blue;
        margin: 0 20px -1px 0;

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            margin: 0 15px -1px 0;
        }

        &.active,
        &.show .nav-link {
            color: $gray-900;
            background-color: $duckegg-blue;
        }

        &:last-child {
            margin: 0 0 -1px 0;
        }
    }

    .nav-link {
        border: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        color: $gray-900;
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 1.5;
        padding: 15px 18px;
        margin:0;

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) and (max-width: map-get($grid-breakpoints, "xl") ) {
            font-size: 1.375rem;
            padding: 16px 21px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "xl") ) {
            font-size: 1.5rem;
            padding: 15px 18px;
        }

        &.active {
            background-color: $duckegg-blue;
            border-color: transparent;
        }
    }
}

.tab-content {
    border: 8px solid $duckegg-blue;
    padding: 18px 20px 30px;

    @media all and (min-width: map-get($grid-breakpoints, "md")){
        padding: 38px 28px;
    }
    @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
        border:12px solid $duckegg-blue;
        padding: 40px 45px;
    }

    .form-group.aep-custom .input-group {
        @media all  and (max-width: map-get($grid-breakpoints, "md")) {
           width: 200px;
        }
    }

    .form-group.aep-custom.moveable-label label {
            @media all and (max-width: map-get($grid-breakpoints, "md") ) {
                top: 17px;
            }
    }
}

@media all and (max-width: map-get($grid-breakpoints, "md") ) {
    .align-buttons {
        display: table;
        margin-top: 25px;
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
    }
}

.search-tabs {
    .nav-tabs {
        margin-bottom: 10px;
        border: 1px solid $dark-gray;
        border-radius: 0.25rem;
        width: 100%;
        padding: 3px;

        .nav-link {
            font-size: 1rem;
            border-radius: 0.25rem;
            width: calc(50% - 2px); //calculate 1/2 width of container minus half the margin
            background-color: $light-gray;
            margin: 0 4px 0 0; //use right margin only between elements
            text-align: center;

            &:last-child{
                margin-right: 0; //remove right margin from last element
            }

            @media all and (min-width: map-get($grid-breakpoints, "sm") ) and (max-width: map-get($grid-breakpoints, "md") ) {
                padding: 9px 18px;
                //width: 45%; //since width is now calculated it works for all screen sizes
            }

            @media all and (min-width: map-get($grid-breakpoints, "md") ) and (max-width: map-get($grid-breakpoints, "xl") ) {
                padding: 9px 18px;
                //width: 47%;
            }

            @media all and (min-width: map-get($grid-breakpoints, "lg") ) and (max-width: map-get($grid-breakpoints, "xl") ) {
                padding: 9px 18px;
                //width: 48.5%;
            }

            @media all and (min-width: map-get($grid-breakpoints, "xl") ) {
                //width: 49%;
                padding: 9px 18px;
            }

            //the following sets the padding for all screen sizes sm-xl
            //the above (4) media queries may safely be deleted
            @media all and (min-width: map-get($grid-breakpoints, "sm" )){
                padding: 9px 18px;
            }

            &:hover, &:active, &.active {
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(6800%) hue-rotate(190deg) brightness(124%) contrast(100%);
                }
            }

            &:hover {
                color: $white;
                background-color: $dark-gray;
            }

            &.active {
                background-color: $link-blue;
                color: white;

                &:hover {
                    background-color: $dark-blue;
                }
            }
        }

        @media all and (min-width: map-get($grid-breakpoints, "xs")){
            width:75%;
        }

        @media all and (min-width: map-get($grid-breakpoints, "sm")){
            width:55%;
        }

        @media all and (min-width: map-get($grid-breakpoints, "md")){
            width:41%;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg")){
            width:40%;
        }
    }

    .tab-content{
        width:inherit;
    }

    &.agency {
        .nav-tabs {
            width: 100%;

            @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
                width: 80%;
            }

            .nav-link {
                text-decoration: none;
                width: 48.5%;
                padding:9px;

                span {
                    color: $dark-gray;
                    text-transform: initial;
                    text-decoration: none;
                }
                &:hover {
                    color: $white;
                    background-color: $dark-gray;
                    text-decoration: none;
                }

                &.active {
                    background-color: $link-blue;
                    color: white;
                    text-decoration: none;

                    &:hover {
                        background-color: $dark-blue;
                    text-decoration: none;
                    }
                }


                @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
                    width: 48.5%;
                }

            }
        }
            .css-2b097c-container {
                width: 100%;
            }
            .css-yk16xz-control {
                border-color: $gray-900;
                cursor: text;
                height: 57px;

                &:hover {
                    border-color: $blue;
                }
            }
            .css-1wa3eu0-placeholder {
                font-size: 1rem;
                top: 45%;
            }
            .agency-name-wrapper {
                &.form-group.aep-custom input, &.form-group.aep-custom select, &.form-group.aep-custom textarea {
                    box-shadow: none;
                    height: 25px;
                        &:active, &:focus {
                        box-shadow: none;
                        }
                }
                .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
                    height: 65px;
                }

                &.form-group.aep-custom.moveable-label label {
                    top: 4px;
                    &.MuiFormLabel-filled {
                        top: 8px;
                        left: -1px;
                    }
                    &.Mui-focused {
                        top: 8px;
                        left: -3px;
                    }
                }
                .css-tlfecz-indicatorContainer {
                    cursor: pointer;
                }
                .input-select__icon svg {
                    width: 25px;
                    height: 25px;
                }
            }

        .tab-content {
            padding: 0;
        }
    }

    .tab-content {
        border: none;
        padding: 40px 45px;

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            padding: 18px 0px 30px 0px;
        }
        @media all and (max-width: map-get($grid-breakpoints, "lg") ) {
            padding: 20px 0;
        }
    }

    .btn {
        margin-left: 0px;
    }

    //this overrides the default classes & the classes directly above
    .tab-content{
        padding:0;

        .form-group.aep-custom .input-group {
            @media all  and (max-width: map-get($grid-breakpoints, "md")) {
                width: 100%;
            }
        }
    }

    .btn{
        margin:0;
    }

    .bordered-form {
        padding-bottom: 0px;
    }
}

.account-summary-tabs {
    .nav-tabs {
        padding: 4px;
        background-color: $duckegg-blue;

        .nav-link {
            //padding: 20px 5px;
            &.active {
                background-color: white;
            }
        }
    }

    .tab-content {
        border: none;
        padding: 10px 0;

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            padding: 18px 0px 30px 0px;
        }
    }

    .btn {
        margin-left: 0px;
    }

    .bordered-form {
        padding-bottom: 0px;
    }
}