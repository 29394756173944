﻿//----------------------------------------------
// Borders
//----------------------------------------------

.border-thick {
    border-width: 12px !important;
}
.border-duckegg {
    border-color: $duckegg-blue !important;
}
.border-dark-blue {
    border-color: $dark-blue !important;
}
.border-link-blue {
    border-color: $link-blue !important;
}
.border-teal {
    border-color: $teal !important;
}
.border-orange {
    border-color: $orange !important;
}
.border-green {
    border-color: $green !important;
}