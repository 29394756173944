@media (min-width: 768px){
  .btn.btn-danger.btn-global-login {
    display:block;
  }
}
/* #tester {
    width: 50px;
  } */
/* @media all and (min-width: 480px) {
    .login-banner {
        background: url('./Login_banner.jpg') top repeat-y;
        background-size: 490px 150px;
    }
    .group-spacing {
        margin-top: 1.5rem !important;
    }

    .bottom-gutter {
        margin-bottom: 1.5rem !important;
    }

    .line-spacing {
        margin-top: 0.5rem !important;
    }

    .outer {
        width: 200px;
        margin: 0 auto;
      }

      .inner {
        margin-left: 50px;
        float: left;
      }

      .columns {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        width: 100%;
        float: left; }
  } */



