@import '../variables/_vars-forms.scss';

//----------------------------------------------
// FORMS
//----------------------------------------------

$strength-gap: 6px;
$strength-colors: (
  darkred,
  orangered,
  orange,
  yellowgreen,
  green
);

.form-processing-overlay {
    display: none;
    position: fixed;
    z-index: 2000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255,255,255,0.75);

    .fpo-content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .fpo-text {
        text-align: center;
        margin-top: 20px;

        h2 {
            margin-bottom: 0;
        }
    }
}

.bordered-form {
    border: 8px solid $duckegg-blue;
    box-sizing: padding-box;
    padding: 28px 18px;

    &.border-bottom-none{
        border-bottom:none;
    }
    &.heading-form{
        padding: 20px 18px;
    }

    @media (min-width: map-get($grid-breakpoints, "md") ) {
        padding: 38px 28px;
    }

    @media (min-width: map-get($grid-breakpoints, "lg") ) {
        padding: 48px;
        border-width: 12px;
    }

    .ux-radios + span.invalid-feedback {
        margin-left: 15px;
    }

    .ux-radios + span.invalid-feedback,
    .ux-checkboxes + span.invalid-feedback,
    .chips + span.invalid-feedback,
    .check + span.invalid-feedback,
    .radio + span.invalid-feedback {
        position: relative;
        top: -10px;
    }
}


.test-form {
    display: none;
}

// TEXTBOX FIELDS WITH LABELS THAT MOVE ON FOCUS
.form-group.aep-custom {
    position: relative;
    display: block;

    &.contains-textarea {
        min-height: 11.5rem;

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            min-height: 12.3125rem;
        }
    }

    input,
    select,
    textarea {
        border-color: $dark-gray;
        margin-bottom: 5px;

        &:focus, &:active {
            outline: none;
            box-shadow: inset 0 0 0 1px $blue;
            border-color: $blue;
        }

        &:hover {
            border-color: $blue;
        }

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            margin-bottom: 7px;
        }
    }

    input,
    select {
        height: 40px;

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            height: 40px;
            margin-bottom: 7px;
        }
    }

    textarea {
        height: 125px;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $dark-gray;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $dark-gray;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: $dark-gray;
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-right: 2rem;
        background: $form-dropdown-arrow-icon;
        background-size: 14px 6px;

        &:hover {
            cursor: pointer;
        }
    }


    .form-control.is-invalid {
        border-color: $form-feedback-invalid-color;
        box-shadow: inset 0 0 0 1px $form-feedback-invalid-color;
    }

    input.form-control.is-valid,
    input.form-control.is-invalid,
    textarea.form-control.is-valid,
    textarea.form-control.is-invalid {
        background-image: none;
        background-size: $form-input-icon-size-sm;

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            background-size: $form-input-icon-size-lg;
        }
    }

    input.form-control.is-valid {
        &.has-formatting {
            border-color: $form-feedback-valid-color;
            box-shadow: inset 0 0 0 1px $form-feedback-valid-color;
            background-image: $form-feedback-icon-valid;
        }
    }

    input.form-control.is-invalid {
        &.has-formatting.has-value {
            background-image: $form-feedback-icon-invalid;
        }
    }

    .input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    label {
        margin-bottom: 10px;
    }

    &.moveable-label {
        label {
            position: absolute;
            z-index: 4;
            background-color: $white;
            -webkit-transition: all .2s;
            transition: all .2s;
            pointer-events: none;
            white-space: nowrap;
            top: 10px;
            left: 10px;
            font-size: 0.875rem;
            padding: 0 3px;
            min-width: calc(100% - 2.5rem);

            @media all and (min-width: map-get($grid-breakpoints, "md") ) {
                top: 17px;
                font-size: 1rem;
            }
        }

        input:focus ~ label, select:focus ~ label, select:valid ~ label, input:not(:placeholder-shown) ~ label {
            top: -9px;
            font-size: 0.75rem;
            min-width: 0;

            @media all and (min-width: map-get($grid-breakpoints, "md") ) {
                top: -10px;
                font-size: 0.875rem;
            }
        }

        input[readonly] ~ label {
            background-color: #e9ecef;
        }

        input[readonly]:focus ~ label {
            background-color: $white;
        }

        input:focus ~ label, select:focus ~ label, select:valid ~ label, input:not(:-ms-input-placeholder) ~ label {
            top: -9px;
            font-size: 0.75rem;
            min-width: 0;

            @media all and (min-width: map-get($grid-breakpoints, "md") ) {
                top: -10px;
                font-size: 0.875rem;
            }
        }

        input::placeholder {
            color: transparent !important;
        }
        /*&.moved {
            label {
                top: -9px;
                font-size: 0.75rem;
                min-width: 0;

                @media all and (min-width: map-get($grid-breakpoints, "md") ) {
                    top: -10px;
                    font-size: 0.875rem;
                }
            }
        }*/
    }

    &.hide-moved-label.moved {
        label {
            visibility: hidden;
        }
    }

    select:disabled,
    input:disabled {
        background-color: white;
        color: $gray-600;

        &:hover {
            box-shadow: none;
            border-color: $dark-gray;
            cursor: default;
        }
    }
}

.selector-wrap{
    margin-bottom:15px;
    &.indented{
        margin-left:30px;
    }
}
.selector-wrap.indented, .form-group.indented{
    margin-left: 30px;
}
 @media all and (min-width: map-get($grid-breakpoints, "md") ) {
    .selector-wrap.indented, .form-group.indented {
        margin-left: 40px;
    }
}

 .form-field-helper,
.invalid-feedback {
    display: block;
    font-size: 0.875rem;
    line-height: 1.14;
    margin-top: 0;

    @media all and (min-width: map-get($grid-breakpoints, "md") ) {
        font-size: 1rem;
    }
}

// Radio Buttons & Checkboxes
.radio, .checkbox {
    margin-left: -20px;
    display: block;

    label {
        margin-bottom: 0;
        margin-right: 0px;
        margin-left: 5px;

        padding: 7px 15px 9px;
        position: relative;
    }

    input + label:before {
        border: 2px solid $dark-gray;
        content: "";
        cursor: pointer;
        display: inline-block;
        width: 23px;
        min-width: 23px;
        height: 23px;
        margin-right: 10px;
        transition: all 250ms ease;
        -webkit-transition: all 250ms ease;
    }


    input:hover + label:before {
        background-color: $light-gray;
    }

    input:checked + label:before {
        background-color: $blue;
    }


    @media all and (min-width: map-get($grid-breakpoints, "md") ) {
        margin-left: -25px;

        label {
            margin-left: 15px;
            padding: 5px 10px;
            margin-bottom:10px;
        }

        input + label:before {
            width: 30px;
            min-width: 30px;
            height: 30px;
            margin-right: 15px;
        }
    }
}

.chips, .checkbox, .radio {
    label {
        cursor: pointer;
    }
    /* Hiding default inputs */
    input {
        display: none;
    }
}

.users-checkbox {
    margin-right: 10px;
}

.text-align-checkbox {
    display: block;
    margin-top: 17px;
}

.radio {
    input + label {
        display: inline-flex;
        /*align-items: center;*/

        &:before {
            background-clip: content-box;
            background-color: white;
            border-radius: 50%;
            padding: 3px;
        }
    }
    //appearance for checked radiobutton
    input:hover + label:before {
        padding: 0;
    }

    input:checked:hover + label:before {
        background-color: $light-blue;
        padding: 3px;
    }


    @media all and (min-width: map-get($grid-breakpoints, "md")) {

        input + label:before {
            padding: 5px;
        }

        input:checked:hover + label:before {
            padding: 5px;
        }
    }
}

.checkbox {
    input + label {
        display: inline-flex;

        &.manage-requests {
            &:before {
                width: 16px;
                min-width: 16px;
                height: 16px;
                margin-top: 14px;
            }
        }

        &:before {
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-top: 2px;
        }
    }
    // svg white check on blue bg
    input:checked + label:before {
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%22-128%20-128%20768%20768%22%20enable-background%3D%22new%200%200%20512%20512%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M432%2C64L192%2C304L80%2C192L0%2C272l192%2C192l320-320L432%2C64z%22/%3E%0A%3C/svg%3E");
    }


    @media all and (min-width: map-get($grid-breakpoints, "md")) {
        input + label:before {
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin-top: 1px;
        }
    }

    @media all and (min-width: map-get($grid-breakpoints, "lg")) {
        input + label:before {
            margin-top: 3px;
        }
    }
}

.chips {
    margin-left:-15px;
    display:block;
    label {

        margin: 0 0 10px 15px;

        /*&:last-child {
            margin: 0;
        }*/
    }


    input + label {
        background-color: #fff;
        border-radius: 50px;
        border: solid 1px $dark-gray;
        font-size: 14px;
        -moz-user-select: -moz-none;
        -ms-user-select: none;
        padding: 0.75rem 1.4rem;
        transition: all 250ms ease;
        -webkit-transition: all 250ms ease;
        -webkit-user-select: none;
        user-select: none;
    }

    input:hover + label {
        background-color: $light-gray;
    }

    input:checked + label {
        background-color: $blue;
        border-color: $blue;
        color: white;
        transition: all 250ms ease;
        -webkit-transition: all 250ms ease;
    }

    @media all and (min-width: map-get($grid-breakpoints, "md") ) {
        font-size: 16px;
        margin-left:-20px;
        label {
            margin-left: 20px;
        }
    }

    + .invalid-feedback{
        margin-bottom:15px;
        margin-top:0;
    }
}

#selectors {
    .border-light-gray {
        border: 1px solid $light-gray;
    }

    #importStatementList, ul {
        background-color: $duckegg-blue;
    }
}



a.text-reg {

    &:hover {
        text-decoration: underline;
        text-decoration-color: $icon-gray;
    }

    &:focus,
    &:active {
        color: $icon-gray;
    }
}

.error {
    color: #DA291C;
    line-height: $line-height-normal;
}

.form-red {
    background-color: #DA291C;
    color: white;
    padding: 20px 20px;

    .login-error {
        font-size: 16px;
    }

    *, a:hover, a:visited, .checkbox label {
        color: white;
    }

    .label {
        line-height: 1.2;
    }

    a:hover {
        text-decoration-color: white;
    }

    label, input {
        color: #4A4A4A;
    }

    input:-ms-input-placeholder {
        color: #4A4A4A;
    }

    input::-moz-placeholder {
        color: #4A4A4A;
    }

    input::placeholder {
        color: #4A4A4A;
    }

    .form-group label {
        visibility: hidden;
        position:absolute;
    }

    .form-group.aep-custom input, .form-group.aep-custom select, .form-group.aep-custom textarea {
        border-color: white;

        &:active, &:focus {
            box-shadow: none !important;
        }
    }

    .radio input + label:before, .checkbox input + label:before {
        border-color: white;
    }

    .radio input:checked + label:before, .checkbox input:checked + label:before {
        background-color: white;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%22-128%20-128%20768%20768%22%20enable-background%3D%22new%200%200%20512%20512%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20fill%3D%22%234a4a4a%22%20d%3D%22M432%2C64L192%2C304L80%2C192L0%2C272l192%2C192l320-320L432%2C64z%22/%3E%0A%3C/svg%3E");
    }

    .btn.btn-secondary-white {
        background-color: transparent;

        &:hover {
            background-color: white;
        }
    }

    .error {
        padding-left: 20px;
        position: relative;
        font-weight: $font-weight-bold;

        &:before {
            content: "";
            position: absolute;
            width: 4px;
            height: calc(100% - 12px);
            border-left: solid 4px #f0b323;
            left: 0;
            top: 6px;
        }
    }

    .invalid-feedback {
        position: relative;
        padding-left: 12px;

        &:before {
            content: "*";
            position: absolute;
            width: 4px;
            height: calc(100% - 6px);
            left: 0;
            top: 3px;
        }
    }

    @media all and (min-width: map-get($grid-breakpoints, "xs") ) {
        padding-left: 30px;
        padding-right:30px;
    }

    @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
        padding: 20px 45px 30px;
    }
}

.ai-more-info {
    cursor: pointer;
}

// date chooser classes //
.date-chooser {
    position: relative;
    /*margin-bottom:-35px;*/
}

.date-chooser ul {
    list-style: none;
    margin:0;
    padding:0;
}

.date-chooser ul li {
    display:inline-block;
}

// .date-chooser-example-container .date-chooser .form-group {
//     /*display: block;*/
// }

.date-chooser div.calendar-container {
    display: none;
    position: absolute;
    z-index: 22;
    top: 50px;
    left: -35px;
    width: 285px;
    background-color: #fff;
    border:1px solid #ccc;
}

.date-chooser div.calendar-controls {
    background-color: #fff;
}

.date-chooser div.calendar-controls a.button {
    margin-bottom: 0;
}

.date-chooser div.calendar-controls a.btn-primary {
    background-color: #009cde;
    border: 1px solid #009cde;
    font-size: 1.0rem;
    padding: 0 12px;
    line-height: 1.9;
}

.date-chooser div.calendar-controls .row {
    margin-left: 0;
    margin-right: 0;
}

.date-chooser div.calendar-controls .text-left {
    padding: 0.25em 0 0 0.25em;
}

.date-chooser div.calendar-controls .text-center {
    padding: 0.5em 0;
    text-transform: uppercase;
    font-size:1.2rem;
}

.date-chooser div.calendar-controls .text-right {
    padding: 0.25em 0.25em 0 0;
}

.date-chooser ul.calendar-header {
    background-color: #fff;
    color: #4a4a4a;
    border-top:1px solid #4a4a4a;
    border-bottom:1px solid #4a4a4a;
}

.date-chooser ul.calendar-header li {
    border: 1px solid #fff;
    text-align: center;
    font-size: 0.9rem;
    text-transform:uppercase;
    font-weight:bold;
    padding: 3px 0;
    flex-basis: calc(100% / 7);
}


.date-chooser ul.calendar li {
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 1.1rem;
    flex-basis:calc(100% / 7);
}
.date-chooser ul.calendar li a {
    display:block;
    color:#4a4a4a;
    padding:6px 0;
}

.date-chooser ul.calendar li.empty,
.date-chooser ul.calendar li.empty .button {
    background-color: #dcdcdc;
    color:#4a4a4a;
}

.date-chooser ul.calendar li.empty .button:before {
    content: "-";
}

.date-chooser ul.calendar li:nth-child(n+8) {
    border-top-width: 0;
}

.date-chooser ul.calendar li:not(:nth-child(7n+1)) {
    border-left-width: 0;
}

.date-chooser ul.calendar li > .button {
    margin-bottom: 0;
    background-color: #fff;
    color: #4a4a4a;
    text-align: center;
    font-size: 1.1rem;
    border-right-width: 0;
}

.date-chooser ul.calendar li > .button:hover {
    background-color: #f1f1f1;
    cursor:pointer;
}

.date-chooser ul.calendar li > .button.selected {
    /*background-color: #e49c00;*/
    background-color: $dark-blue;
    color: #fff;
}

.date-chooser ul.calendar li > .button.disabled,
.date-chooser ul.calendar li > .button.disabled:hover {
    background-color: #f3f3f3;
    color:#4a4a4a;
    cursor: default;
}

.date-chooser a.calendar-link {
    // background-image: url(/lib/images/svgs/calendar.svg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    display: inline-block;
    width: 100%;
    height: 30px;
    position:absolute;
    top:5px;
}

@media all and (min-width: map-get($grid-breakpoints, "sm") ) {
    .date-chooser div.calendar-container {
        left: 0;
        width: 300px;
    }
    .date-chooser {
        margin-bottom:0;
    }
    .date-chooser-example-container .date-chooser .form-group {
        display: inline-block;
        width:80%;
    }
    .date-chooser a.calendar-link {
        top:-2px;
        background-position: top left;
        margin-top: 0;
        width: 44px;
        height: 44px;
        background-size: contain;
    }

}
@media all and (min-width: map-get($grid-breakpoints, "md") ) {
    .date-chooser a.calendar-link {
        width: 60px;
        height: 60px;
    }
    .date-chooser div.calendar-container {
        top: 70px;
        left:0;
        width: 345px;
    }
    .date-chooser div.calendar-controls a.btn-primary {
        font-size: 1.2rem;
        padding: 0 14px;
        line-height: 1.9;
    }
    .date-chooser ul.calendar-header li {
        font-size: 1.0rem;
        padding: 0.25em 0;
    }
    .date-chooser ul.calendar li {
        font-size: 1.5rem;
    }

    date-chooser ul.calendar li > .button {
        font-size: 1.3rem;
    }
}


/* Password Strength **************************/
#divStrengthBar {
    margin-top: -10px;

    @media all and (min-width: map-get($grid-breakpoints, "md") ) {
        margin-top: -13px;
    }
}
div[data-password-strength] span {
    height: 0.25rem;
    display: inline-block;
    border: 1px solid $icon-gray;
    border-radius: 5rem;
    background-color: $icon-gray;
    width: 25%;
}

div[data-password-strength] span:nth-child(-n+3) {
    width: 23%;
    margin-right: 2%;
}

div[data-password-strength="1"] span:nth-child(-n+1), div[data-password-strength="-1"] span:nth-child(-n+1) {
    background-color: $red;
    border: 1px solid $red;
}

div[data-password-strength="2"] span:nth-child(-n+2) {
    background-color: $orange;
    border: 1px solid $orange;
}

div[data-password-strength="3"] span:nth-child(-n+3) {
    background-color: $link-blue;
    border: 1px solid $link-blue;
}

div[data-password-strength="4"] span:nth-child(-n+4), div[data-password-strength="4"] span:nth-child(-n+4) {
    background-color: $green;
    border: 1px solid $green;
}

div[data-password-strength="1"] #divStrength::after {
    font-size: 0.65rem;
    color: $dark-gray;
    float: right;
    content: attr(data-strength-message);
}

div[data-password-strength="1"] #divStrength::after {
    content: "Weak";
    float: right;
    font-size: 0.7rem;
}

div[data-password-strength="2"] #divStrength::after {
    content: "Fair";
    float: right;
    font-size: 0.7rem;
}

div[data-password-strength="3"] #divStrength::after {
    content: "Good";
    float: right;
    font-size: 0.7rem;
}

div[data-password-strength="4"] #divStrength::after {
    content: "Strong";
    float: right;
    font-size: 0.7rem;
}

div[data-password-strength="-1"] #divStrength::after {
    float: right;
    font-size: 0.7rem;
}

#divStrengthBar + span {
    border: none;
    background-color: transparent;
    padding: 0.1rem;
    text-align: left;
    font-size: 0.7rem;
}

.address-search-results {
    padding: 15px 0;
    box-shadow: 0 0 2px 1px rgba(74, 74, 74, 0.5);
    margin-top: -40px;
    font-size: 1rem;

    a.result {
        padding: 10px 20px 10px 42px;
        position: relative;
        display: block;

        &:hover, &:active {
            background-color: $light-gray;
        }

        .ai-pin {
            width: 12px;
            height: 18px;
            display: block;
            position: absolute;
            left: 20px;
        }

        text-decoration: none;
        font-size: 1rem;
        line-height: 1.15;
        color: $dark-gray;

        .line-one {
            font-weight: $font-weight-bold;
            display: block;
        }
    }
}


.two-block {
    width: 100%;
    clear: both;
    &.agency-register, &.agency-manage {
        > .first {
            width: 40%;
        }
        > .second {
            width: 60%;
        }
    &.reverse {
        > .first {
            width: 80%;
        }
        > .second {
            width: 20%;
        }
    }
    }
}

  .two-block  > .first {
    float: left;
    width: 30%;
    margin-bottom: 0;
  }
  .two-block  > .second {
    float: right;
    width: 70%;
    margin-bottom: 0;
  }


  section .print-view button {
    font-size: 1.25rem !important;
  }
  section .view-all {
    font-size: 1.375rem;
    padding: 0;
    margin: 0;
  }
  .center-align {
    width: 100%;
    margin: auto;
  }

  @media (min-width: 768px){
  section .view-all  {
      font-size: 1.25rem;
  }
  }
  @media (max-width: 767px){
    section .view-all  {
        font-size: 1.125rem;
    }
  }
  @media (max-width: 1023px) {
    section .print-view {
      text-align: left !important;
    }
    section .print-view button {
      padding-left: 0;
    }
  }
.loader {
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: none;
    position: fixed;
    background: #FFF;
    opacity: 0.85;
    img {
        width: 60px;
        height: auto;
        display: flex;
        top: 40%;
    }
    .span-text {
        margin-top: 60px;
        margin-bottom: 10px;
        height: auto;
        display: block;
        &.span-text-small {
            margin-top: 0;
            text-align: center;
        }
    }
}

.centered-loader {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .container .MuiStepIcon-root {
      width: 40px;
      height: 40px;
    &.MuiStepIcon-active {
       color: $dark-blue;
    }
    &.MuiStepIcon-completed {
        color: #93d400;
    }
  }
  .container .register-right-section {
      background-color: $duckegg-blue;
  }

  .center-align-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }


.strength-meter {
    position: relative;
    height: 5px;
    background: #ddd;
    margin: 7px 0;
    border-radius: 2px;
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      height: inherit;
      background: transparent;
      display: block;
      border-color: #fff;
      border-style: solid;
      border-width: 0 $strength-gap 0;
      position: absolute;
      width: calc(20% + #{$strength-gap});
      z-index: 2;
    }
    &:before {
      left: calc(20% - #{($strength-gap / 2)});
    }
    &:after {
      right: calc(20% - #{($strength-gap / 2)});
    }
  }

  .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    // border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
    @for $i from 1 through 5 {
      &[data-strength="#{$i - 1}"] {
        width: (20% * $i);
        background: nth($strength-colors, $i);
      }
    }
  }

  .placeholder-checkbox {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-top: 14px;
  }

   .width-adjustment {
          width: 420px;
  }
  .manage-requests {
    .modal-backdrop.show {
        opacity: 0.3;
    }
  }

  @media all and (max-width: 1023px) {
    .agency-review {
        .width-adjustment {
            width: 380px;
        }
    }
  }
  @media all and (max-width: 767px) {
    .agency-review {
        .width-adjustment {
            width: 100%;
        }
    }
  }
  @media all and (max-width: 480px) {
    .agency-review {
        .width-adjustment {
            width: 100%;
        }
        .two-block {
            &.agency-register {
                > .first {
                    width: 100%;
                }
                > .second {
                    text-align: left !important;
                    width: 100%;
                }
            }
          }
    }
    .manage-requests.table-row-dividers {
        thead {
            th {
                b {
                    margin-left: 0 !important;
                }
            }
        }
        td {
            padding: 0;
            &:last-child {
                margin: 20px 0 30px;
            }
        }
    }
  }

  .form-group.aep-custom .account-search {
    height: 55px;
}

a.iforgotHyperLink:link,
a.iforgotHyperLink:visited,
a.iforgotHyperLink:hover,
a.iforgotHyperLink:active{
    text-decoration: none; 
    color: #DA291C;
    
}

/* datepicker form manage appointment */
.section .react-datepicker-popper{
  z-index: 4;
}
.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    font-weight: 400;
}
.react-datepicker__navigation--next:focus {
    outline: none;
}
.react-datepicker-wrapper {
    width: 65%;
    margin-right: 10px;
}
.section .form-group.aep-custom .react-datepicker-wrapper input {
    height: 50px;
    width: 100%;
    color: #495057;
    background-color: #FFF;
    cursor: pointer;
    background-clip: padding-box;
    border: 1px solid #4A4A4A;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    font-size: 1rem;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    &:hover {
        border-color: #009CDE;
    }
}
.calendar-icon {
    position: relative;
    right: 40px;
}
.section .react-datepicker__navigation--next {
    border-left-color: #000;
}
.section .react-datepicker__navigation--previous {
    border-right-color: #000;
}
