
.panel {
    margin-bottom: 30px;
    flex-grow: 1;
    background-color: $light-gray;
    padding: 18px 14px;

    button.panel-link{
        border:none;
        background-color:transparent;
    }

    .panel-link {
        background-image: url("data:image/svg+xml, #{$chevron-right}");
        background-repeat: no-repeat;
        padding-right: 40px;
        background-position: right 10px center;
        background-size: 17px;
        min-height: 40px;
        margin-right: -10px;
        display: flex;
        align-items: center;
        color: $dark-gray;
        transition: all .25s;

        &:hover {
            background-image: url("data:image/svg+xml, #{$chevron-right-hover}");
            text-decoration: underline;
            text-decoration-color: $dark-gray;
        }

        .link-text {
            display: none;
        }
    }

    .panel-header {
        display: flex;
        margin-bottom: 11px;
        align-items: center;
        h2{
            margin-bottom:0;
        }
        &.has-link {
            margin-top: -9px; // account for large tap area of icon
        }

        .h4 {
            font-weight: 700;
        }

        .header-sub-text {
            line-height: 1.09;
        }


    }
    .panel-small{
            @media all and (min-width: map-get($grid-breakpoints, "md")) {
                display:block;
            }
        }

    .table.panel-table{
        thead th{
            border:none;
            padding-left:0;
        }
        tbody td{
            padding-left: 0;
        }
        tr:first-child td{
            border:none;
        }
    }


    @media all and (min-width: 440px) {
        .panel-header {
            margin-bottom: 15px;
        }
        .panel-link .link-text {
            display: inline-block;
        }
    }

    @media all and (min-width: map-get($grid-breakpoints, "sm")) {
        .panel-link {
            padding-right: 47px;
            background-size: 22px;
        }
    }

    @media all and (min-width: 640px) {
    }

    @media all and (min-width: map-get($grid-breakpoints, "md")) {
        padding: 28px 30px;
        margin-right: 20px;
        margin-bottom: 34px;

        .panel-header {
            margin-bottom: 26px;
        }

        .table-stacked tbody tr:nth-of-type(odd){
    background-color: inherit;
        }
    }

    @media all and (min-width: map-get($grid-breakpoints, "lg")) {
        padding: 30px 34px;

        .panel-link {
            padding-right: 58px;
            background-size: 27px;
        }
    }

    @media all and (min-width: map-get($grid-breakpoints, "xl")) {
        padding: 34px;
        flex-basis:0;

        &.panel-small {
            max-width: 410px;
            min-width: 410px;
        }
    }
}

