﻿@import  '../variables/_vars-cards.scss';
//----------------------------------------------
// CARDS / TILES
//----------------------------------------------

#glossary-card-container {
    h2 {
        margin: 20px 0 30px 0;

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            margin: 40px 0 30px 0;
        }
    }

    .glossary-card {
        padding: 15px;

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            padding: 30px 0 30px 30px;
        }

        &:nth-child(odd) {
            background-color: $gray-100;
        }

        h3 {
            margin-top: 0.75rem;

            @media (min-width: map-get($grid-breakpoints, "md") ) and (max-width: map-get($grid-breakpoints, "lg") ) {
                margin-top: 0.8125rem;
            }

            @media (min-width: map-get($grid-breakpoints, "lg") ) {
                margin-top: 0;
            }
        }
    }
}


