﻿/* variables used throughout our scss AND bootstrap variables */


//----------------------------------------------
// GRID
//----------------------------------------------

/*Breakpoints by screen size width:
- X-Small: 0 - 575px,
- Small: 576px – 767px
- Medium: 768px - 1023px
- Large: 1024px – 1279px
- X-large: 1280 – 1439px
- XX-large: 1440px & up*/
/*$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 1024px, xl: 1280px, xxl: 1440px);*/
$grid-breakpoints: (xxs: 0, xs:375px, sm: 576px, md: 768px, lg: 1024px, xl: 1280px, xxl: 1440px);
$container-max-widths: ( xxl: 1440px);



/*gutters:
(xs, 20px);
(sm, 20px);
(md, 20px);
(lg, 32px);
(xl, 40px);
*/
$grid-gutter-width: 30px;


$spacers: (n20: -20px, n16: -16px, n12: -12px, n10: -10px, n8: -8px, n4: -4px, 0: 0px, 8: 8px, 10: 10px, 12: 12px, 15: 15px, 16: 16px, 18: 18px, 20: 20px, 24: 24px, 25: 25px, 30: 30px, 32: 32px, 35: 35px, 40: 40px, 45: 45px, 50: 50px, 55: 55px, 60: 60px, 65: 65px, 70: 70px, 75: 75px, 80: 80px, 90: 90px, 100: 100px); 
