﻿// grid and gutters

// responsive gutters for each breakpoint
@include responsive-gutter(xxs, 20px);
@include responsive-gutter(sm, 30px);
@include responsive-gutter(lg, 40px);
@include responsive-gutter(xl, 60px);
@include responsive-gutter(xxl, 60px);


@include responsive-container(xxs,20px);
@include responsive-container(xs,30px);
@include responsive-container(lg,50px);
@include responsive-container(xl,70px);
@include responsive-container(xxl,70px);


.row {
    &.no-gutters {
        margin-right: 0;
        margin-left: 0;
    }

    &.overflow-container {
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);

        &:before, &:after {
            display: table;
            content: " ";
        }

        &:after {
            clear: both;
        }
    }
}

/*-----------------------------*/
.form-row {
    margin-left: -10px;
    margin-right: -10px;

    & > .col,
    & > [class*=col-] {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/*-----------------------------*/
.divider-sm {
    margin: 0;
    border-top: 1px solid $gray-600;

    &.red {
        border-color: $red;
    }
}

.divider-lg {
    margin:0;
    border-top: 15px solid $gray-100;

    &.red{
        border-color: $red;
    }

    @media (min-width: map-get($grid-breakpoints, "xl") ) {
        border-top-width: 20px;
    }

}


/* Global spacing between components/sections */
.mb-st {
    margin-bottom: 30px !important;

    @media (min-width: map-get($grid-breakpoints, "md") ) {
        margin-bottom: 40px !important;
    }

    @media (min-width: map-get($grid-breakpoints, "lg") ) {
        margin-bottom: 60px !important;
    }
}

.mt-st {
    margin-top: 30px !important;

    @media (min-width: map-get($grid-breakpoints, "md") ) {
        margin-top: 40px !important;
    }

    @media (min-width: map-get($grid-breakpoints, "lg") ) {
        margin-top: 60px !important;
    }
}

.my-st {
    margin-bottom: 30px !important;
    margin-top: 30px !important;

    @media (min-width: map-get($grid-breakpoints, "md") ) {
        margin-bottom: 40px !important;
        margin-top: 40px !important;
    }

    @media (min-width: map-get($grid-breakpoints, "lg") ) {
        margin-bottom: 60px !important;
        margin-top: 60px !important;
    }
}


/* NOTE: Use javascript to apply this class if overflow should temporarily be hidden, such as on an overlay */
.no-scroll {
    overflow: hidden;
}

    /*-----------------------------*/
    /* Grid overlay                */
    :root {
        --offset: 1.25rem;
        --max_width: 76.25rem;
        --columns: 6;
        --gutter: 1.25rem;
        --baseline: 3rem;
        --baseline-shift: 2rem;
        --color: hsla(204, 80%, 72%, 0.25);
    }

    @media (min-width: map-get($grid-breakpoints, "sm") ) {
        :root {
            --offset: 1.25rem;
            --gutter: 1.25rem;
            --color: hsla(286, 51%, 44%, 0.25);
            --columns: 12;
        }
    }

    @media (min-width: map-get($grid-breakpoints, "md") ) {
        :root {
            --offset: 2rem;
            --gutter: 2rem;
            --color: hsla(286, 51%, 44%, 0.25);
        }
    }

    @media (min-width: map-get($grid-breakpoints, "lg") ) {
        :root {
            --offset: 2rem;
            --gutter: 2rem;
            --color: hsla(286, 51%, 44%, 0.25);
        }
    }

    @media (min-width: map-get($grid-breakpoints, "xl") ) {
        :root {
            --offset: 2.5rem;
            --gutter: 2.5rem;
            --color: hsla(286, 51%, 44%, 0.25);
        }
    }

    @media (min-width: map-get($grid-breakpoints, "xxl") ) {
        :root {
            --offset: 2.5rem;
            --gutter: 2.5rem;
            --color: hsla(286, 51%, 44%, 0.25);
            --max_width: 80rem;
        }
    }
    /* Helper variables */
    :root {
        --repeating-width: calc(100% / var(--columns));
        --column-width: calc((100% / var(--columns)) - var(--gutter));
        --background-width: calc(100% + var(--gutter));
        --background-columns: repeating-linear-gradient( to right, var(--color), var(--color) var(--column-width), transparent var(--column-width), transparent var(--repeating-width) );
        --background-baseline: repeating-linear-gradient( to bottom, var(--color), var(--color) 1px, transparent 1px, transparent var(--baseline) );
    }

    html.grid-overlay {
        position: relative;
    }

    html.grid-overlay::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        width: calc(100% - (2 * var(--offset)));
        max-width: var(--max_width);
        min-height: 100vh;
        content: '';
        background-image: var(--background-columns), var(--background-baseline);
        background-size: var(--background-width) 100%;
        background-position: 0 var(--baseline-shift);
        z-index: 1000;
        pointer-events: none;
    }













