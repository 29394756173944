﻿//----------------------------------------------
// BUTTONS
//----------------------------------------------

.btn {
    border-radius: 4px;
    font-size: 1.65rem;
    font-weight: normal;
    margin-left: -5px;
    margin-right: 35px;
    padding: 0 20px;
    border: none;

    &.btn-min-w {
        min-width: 160px;
    }

    &.btn-w-100 {
        min-width: 100%;
    }

    &.btn-primary {
        background-color: $dark-blue;
        color: $white;
        border: 1px solid $dark-blue;

        &.disabled, &:disabled, &.disabled:hover, &:disabled:hover {
            background-color: $dark-blue;
            cursor: default;
            border: 1px solid $dark-blue;
        }

        &:hover {
            background-color: $blue;
            color: $white;
            border: 1px solid $blue;
        }

        &:focus, &.focus {
            box-shadow: none;
        }

        &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
            background-color: $blue;
            border: 1px solid $blue;
        }
    }

    &.btn-secondary, &.btn-default {
        color: $dark-gray;
        background-color: rgba(255, 255, 255, 0.23);
        border: solid 1px $dark-gray;

        &.disabled, &:disabled, &.disabled:hover, &:disabled:hover {
            cursor: default;
            opacity: 0.5;
            background-color: rgba(255, 255, 255, 0.23);
            color: $dark-gray;
        }

        &:hover {
            background-color: $dark-gray;
            color: $white;
        }

        &:focus, &.focus {
            box-shadow: none;
        }
    }

    &.btn-secondary-white {
        color: $white;
        background-color: rgba(255,255,255,0.2);
        border: solid 1px $white;

        &:hover {
            color: $dark-gray;
            background-color: #FFF;
        }
    }

    &.btn-bar {
        color: $body-color;
        width: 100%;
        display: block;
        background-color: $gray-100;
        margin-bottom: 40px;
        margin-top: 22px;
        font-family: $font-family-base;
        font-weight: $font-weight-bold;
        border: solid 1px $gray-100;

        &::after {
            content: "";
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 8px solid $dark-gray;
            display: inline-block;
            top: 4px;
            position: relative;
            margin-left: 16px;
            border-radius: 20%;
        }

        &:hover {
            border: solid 1px $dark-gray;
        }

        &.arrow-down {
            &::after {
                border-top: 8px solid $dark-gray;
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                top: 9px;
            }
        }

        &.arrow-up {
            &::after {
                border-bottom: 8px solid $dark-gray;
                border-right: 11px solid transparent;
                border-left: 11px solid transparent;
                top: 0px;
            }
        }
    }
}

.btn-underline {
    border: 1px solid $dark-gray;
    border-radius: 5px;
    color: $dark-gray;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    display: inline-block;
    padding: 10px 30px;

    &:hover {
        text-decoration-color: $dark-gray;
        color: $dark-gray;
    }

    @media (min-width: map-get($grid-breakpoints, "md") ) {
        margin: 0;
    }

    &.contains-icon {
        position: relative;

        .btn-text {
            padding-left: 36px;
            text-transform: uppercase;
        }

        .btn-document-icon {
            left: 30px;
            top: 11px;
            position: absolute;
            font-size: 1rem;
            text-transform: uppercase;
            max-width: 300px;
            height: 24px;
            width: 24px;
            background-size: 22px 22px;
            background-repeat: no-repeat;
            transition: all .25s;
        }
    }
}

@media all and (min-width: map-get($grid-breakpoints, "sm") ) {
    .btn {
        font-size: 1.375rem; //22px
        line-height: 2; //45px
        padding: 0 25px;

        &.btn-inline {
            font-size: 1.1875rem; //19px
            line-height: 2; //38px
        }

        &.btn-bar {
            line-height: 44px;
        }
    }

}

@media all and (min-width: map-get($grid-breakpoints, "md") ) {
    .btn {
        &.btn-inline {
            font-size: 1.625rem; //26px
            line-height: 2.24; //58px
        }
    }
}

@media all and (min-width: map-get($grid-breakpoints, "lg") ) {
    .btn {
        font-size: 1.5rem; //24px
        line-height: 2.25; //54px
        padding: 0 26px;

        &.btn-inline {
            font-size: 1.625rem; //26px
            line-height: 2.24; //58px
        }

        &.width-100 {
            width: 100%;
        }

        &.btn-bar {
            line-height: 48px;

            &::after {
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-left: 10px solid #4A4A4A;
            }

            &.arrow-down {
                &::after {
                    border-top: 10px solid $dark-gray;
                    border-right: 13px solid transparent;
                    border-left: 13px solid transparent;
                    top: 10px;
                }
            }

            &.arrow-up {
                &::after {
                    border-bottom: 10px solid $dark-gray;
                    border-right: 13px solid transparent;
                    border-left: 13px solid transparent;
                    top: 0px;
                }
            }
        }
    }
}

@media all and (min-width: map-get($grid-breakpoints, "xl") ) {
    .btn {
        font-size: 1.625rem; //26px
        line-height: 2.3077; //60px
        padding: 0 30px;
    }
}



.btn-group {
    display: flex;
    border: 1px solid $dark-gray;
    border-radius: 4px;
    padding: 5px;
    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
        @include border-right-radius(4);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
        @include border-left-radius(4);
    }

    .btn {
        color: $dark-gray;
        background-color: $light-gray;
        border: solid 1px transparent;
        display: flex;
        flex: 1 1 auto !important;
        font-size: 0.875rem;
        margin-right: 5px;
        border-radius: 4px !important;
        height: 40px;
        text-align: center;
        line-height: 1.5;
        padding-right: 10px;
        padding-left: 10px;

        &:hover {
            color: $white;
            background-color: $dark-gray;
        }

        &.selected {
            color: $white;
            background-color: $link-blue;

            &:hover {
                background-color: $dark-blue;
            }
        }


        > span {
            /*display:inline-block;*/
            display: flex;
            align-self: center;
            margin: 4px auto;
        }
    }

    .btn:last-child {
        margin-right: 0;
    }
}
@media all and (min-width: map-get($grid-breakpoints, "sm") ) {
    .btn-group {
        .btn-icon {
            display: inline-block;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            margin: 0 5px 0;
        }

        .btn {
            padding-right: 15px;
            padding-left: 15px;
        }

        .btn-icon.phone {
            // background-image: url("/lib/images/svgs/btn-grp-phone.svg");
        }

        .selected .btn-icon.phone, .btn:hover .btn-icon.phone {
            // background-image: url("/lib/images/svgs/btn-grp-phone-white.svg");
        }

        .btn-icon.address {
            // background-image: url("/lib/images/svgs/btn-grp-address.svg");
        }

        .selected .btn-icon.address, .btn:hover .btn-icon.address {
            // background-image: url("/lib/images/svgs/btn-grp-address-white.svg");
        }

        .btn-icon.meter {
            // background-image: url("/lib/images/svgs/btn-grp-meter.svg");
        }

        .selected .btn-icon.meter, .btn:hover .btn-icon.meter {
            // background-image: url("/lib/images/svgs/btn-grp-meter-white.svg");
        }

        .btn-icon.account {
            // background-image: url("/lib/images/svgs/btn-grp-account.svg");
        }

        .selected .btn-icon.account, .btn:hover .btn-icon.account {
            // background-image: url("/lib/images/svgs/btn-grp-account-white.svg");
        }
    }
}
@media all and (min-width: map-get($grid-breakpoints, "md") ) {
    .btn-group {
        .btn {
            padding-right:20px;
            padding-left:20px;
        }
    }
}
@media all and (min-width: map-get($grid-breakpoints, "lg") ) {
    .btn-group {
        .btn-icon {
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin: 0 5px 0;
        }

        .btn {
            font-size: 1.0rem;
            height: 50px;
            line-height: 1.9;
            padding-right: 25px;
            padding-left: 25px;
        }
    }
}

.approval-row {
    margin-top: 40px;
    padding-left: 10px;
}

.button-without-border {
    color: $blue;
    text-transform: none;
    font-size: 1.35rem;
    font-weight: normal;
    border: none;
    background: #fff;

    &.disabled, &:disabled {
        cursor: default;
        opacity: 0.35;
        &:hover {
            color: $blue;
          }
    }

    &:hover {
      color: $dark-blue;
      background: #fff;
    }

  }
  .button-without-margin {
      margin-right: 0px;
  }
  .button-anchor {
    transition: all .25s;
    text-decoration: underline;
    text-decoration-color: transparent;
    color: $blue;
    border: none;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    &.disabled, &:disabled {
        cursor: default;
        opacity: 0.35;
        &:hover {
            color: $blue;
          }
    }
    &:hover, &.hover {
        color: $dark-blue;
        text-decoration-color: $dark-blue;
    }
  }

  .btn-sso{
      font-size: inherit;
      line-height: inherit;

      &:hover{
          color:$white;
          text-decoration: underline;
      }
      &.btn-nowhite {
          &:hover {
            color: $dark-blue;
          }
      }
  }

  .form-group.aep-custom.moveable-label label.align-label {
      top: 10px;
  }
