﻿
.progress-indicator {
    margin-bottom:20px;
    .steps {
        display: -ms-flexbox !important;
        display: flex !important;
        align-items: center;

        .step-number {
            font-size: 0.875rem;
            font-weight: $font-weight-bold;
            color: $icon-gray;
            background-size: 100%;
            background-position: center center;
            line-height: 25px;
            padding-left: 9px;
            margin-right: 5px;

            &.ai-step-number-selected {
                line-height: 25px;
                padding-left: 9px;
                color: #0078AA;
                width: 25px;
                height: 25px;
            }

            &.ai-checkmark, &.ai-step-number {
                height: 25px;
                width: 25px;
            }

            &.last {
                margin-right: 0;
            }
        }

        .step-bar {
            -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
            height: 10px;
            background-color: #dddddd;
            margin-right: 5px;

            &.step-selected {
                background-color: $link-blue;
            }
        }

        @include media-breakpoint-up("sm") {
            .step-number {
                font-size: 1.25rem;
                line-height: 30px;
                padding-left: 9.5px;
                margin-right: 15px;
                margin-left: 5px;

                &.ai-step-number-selected {
                    line-height: 44px;
                    padding-left: 16px;
                    font-size: 1.5rem;
                    margin-right: 10px;
                    width: 44px;
                    height: 44px;
                }

                &.ai-checkmark, &.ai-step-number {
                    height: 30px;
                    width: 30px;
                }

                &.first {
                    margin-left: 0;
                }

                &.last {
                    margin-right: 0;
                }
            }

            .step-bar {
                margin-right: 10px;
                height: 10px;
            }
        }
    }
}

.next-step-container {
    display: none;
    background-color: $duckegg-blue;
    align-items: center;

    .next-step {
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: 20px 20px;
    }

    @include media-breakpoint-up("md") {
        display: -ms-flexbox;
        display: flex;
    }

    @include media-breakpoint-up("lg") {
        .ai {
            height: 80px;
            /*width: 80px;*/
        }
    }
}
