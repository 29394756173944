﻿/* variables used throughout our scss AND bootstrap variables */

//----------------------------------------------
// TABLES
//----------------------------------------------


$table-cell-padding: .75rem !default;
$table-cell-padding-sm: .3rem !default;

$table-accent-bg: rgba($light-blue, .05) !default;
$border-width: 1px;