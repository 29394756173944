﻿//----------------------------------------------
// ACCOUNT BLOCK
//----------------------------------------------

@mixin flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.account-block-wrapper {

    .accordion .collapse {
        @media (min-width: map-get($grid-breakpoints, "md") ) {
            display: block;
        }
    }

    .abw-title {
        color: $dark-red;
        text-transform: uppercase;
        font-size: 1.125rem;
        font-weight: $font-weight-bolder;
        margin-bottom: 0;

        &.abw-summary {
            font-size: 1.25rem;
            margin-top: 0;

            @media (min-width: map-get($grid-breakpoints, "md") ) {
                font-size: 1.5rem;
            }

            @media (min-width: map-get($grid-breakpoints, "lg") ) {
                font-size: 2rem;
            }
        }

        @media (min-width: map-get($grid-breakpoints, "md") ) {
            font-size: 1.25rem;
            margin-top: 2px;
        }

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            font-size: 1.5rem;
        }
    }

    .abw-open-accordion-icon {
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        // background-image: url('/lib/images/svgs/triangle-down.svg');
        height: 14px;
        width: 18px;
        margin-right: 1px;
    }

    .abw-address-settings-wrapper {
        @include flex-row;
        position: relative;
    }

    .abw-single-account,
    .abw-address,
    .abw-multi-account {
        font-size: 1rem;

        @media (min-width: map-get($grid-breakpoints, "md") ) {
            font-size: 1.125rem;
        }

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            font-size: 1.25rem;
        }
    }

    .abw-title-wrapper {
        @include flex-row;
    }

    .abw-address {
        font-weight: $font-weight-bold;
        padding-right: 30px;

        @media (min-width: map-get($grid-breakpoints, "md") ) {
            margin-top: 0;
        }
    }

    .abw-account-info {
        min-width: 230px;
    }

    .abw-single-account {
        .abw-account-info {
            @media (min-width: map-get($grid-breakpoints, "md") ) {
                @include flex-row;
            }
        }
    }

    .abw-multi-account {

        .abw-account-info {
            @media (min-width: map-get($grid-breakpoints, "md") ) {
                text-align: right;
            }
        }

        @media (min-width: map-get($grid-breakpoints, "md") ) {
            @include flex-row;
        }
    }

    .abw-dropdown-wrapper {
        & > .form-group.aep-custom {
            display: inline-block;
            min-height: 0; // min-height of 0 lets it grow to natural size without extra space below
            min-width: 100%;
            margin-bottom: 0;

            & > select {
                margin-bottom: 0;
            }

            @media (min-width: map-get($grid-breakpoints, "md") ) {
                min-width: 0;
            }
        }
    }

    .abw-gear-icon {
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        // background-image: url('/lib/images/svgs/gear.svg');
        height: 20px;
        width: 20px;
        position: absolute;
        right: 0;
        top: 2px;

        @media (min-width: map-get($grid-breakpoints, "md") ) {
            position: static;
        }

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            height: 25px;
            width: 25px;
        }
    }

    .abw-link {
        text-decoration: none;
        text-transform: uppercase;

        & > * {
            display: inline-block;
            vertical-align: middle;
            color: $dark-gray;
            font-weight: $font-weight-bold;
        }


        .abw-gear-icon {
            margin-right: 3px;
        }
    }

    .abw-divider {
        border-top: 1px solid $gray-600;
        margin: 20px 0 30px 0;

        @media (min-width: map-get($grid-breakpoints, "md") ) {
            border-top: 2px solid $gray-300;
        }
    }
}