//@import '../../aeputilities-styles/styles/scss/site.scss';
$bg-img: url('../Images/banner/customer-assistance.jpg');

body, p, h1, .h1, h2, .h2, h3, .h3, h4, .h4, ul, li, a{
    font-family: din-2014, Arial, Roboto, sans-serif;
}

.task-bar {
    font-family: din-2014, Arial, Roboto, sans-serif;
}

.task-link {
    display: block;
    margin: 0 0 0 15px;
    text-align: center;
    font-weight: 600;
    font-size: 0.95rem;
    line-height: 1;
    padding: 10px 20px;
    text-transform: uppercase;
}

.ai.home {
    width: 28px;
    height: 28px;
    margin: 0 auto 5px auto;
}

.task-text {
    display: block;
}

.ai {
    width: 60px;
    height: 60px;
}

@media (min-width: 400px) {
    .ai {
        width: 80px;
        height: 80px;
    }
}

@media (min-width: 576px) {
    .task-link {
        box-sizing: border-box;
        text-align: left;
        display: flex;
        align-items: center !important;
        min-height: 75px;
        font-size: 1.125rem;
        line-height: 1.125;
    }

    .ai.home {
        display: inline-block;
        width: 39px;
        height: 39px;
        margin: 0 15px 0 10px;
        display: inline-block;
        overflow: visible;
    }

    .ai {
        width: 60px;
        max-width: 39px;
    }

    .task-text {
        display: inline;
    }
}

.banner-container {
    .h1, h4 {
        color: #fff;
        font-family: din-2014, Arial, Roboto, sans-serif;
        text-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
    }

    .h1 {
        font-size: 30px;
        line-height: 32px;

        &.color-white {
            color: #ffffff;
        }

        &.dark-red {
            color: #AF2218;
        }
    }

    h4 {
        font-size: 1rem;
        line-height: 20px;
        font-weight: 300;

        &.color-white {
            color: #ffffff;
        }

        &.dark-gray {
            color: #4A4A4A;
        }
    }

    &.home {
        .h1 {
            font-family: din-2014, Arial, Roboto, sans-serif;
            font-size: 20px;
            line-height: 23px;
            text-shadow: none;
            margin-bottom: 5px;

            @media all and (min-width: 576px){
                font-size: 24px;
                line-height: 27px;
            }

            @media all and (min-width: 768px){
                font-size: 36px;
                line-height: 43px;
            }

            @media all and (min-width: 1024px){
                font-size: 42px;
                line-height: 52px;
            }

            @media all and (min-width: 1280px){
                font-size: 48px;
                line-height: 60px;
            }
        }

        h4{
            font-size: 16px;
            line-height: 19px;
            text-shadow: none;

            @media all and (min-width: 768px){
                font-size: 20px;
                line-height: 23px;
            }

            @media all and (min-width: 1024px){
                font-size: 24px;
                line-height: 27px;
            }
        }

        a {
            font-size: 16px;
            line-height: 1.125;
            text-shadow: none;

            // .link-icon {
            //     display: inline-block;
            //     background-image: url("data:image/svg+xml, #{$chevron-right}");
            //     background-size: contain;
            //     background-position: center center;
            //     background-repeat: no-repeat;
            //     height: 12px;
            //     width: 12px;
            //     margin-left: 5px;
            //     margin-bottom: -2px;
            // }

            &.color-white {
                color: #ffffff;

                .link-icon {
                    filter: grayscale(100%) sepia(100%) brightness(200%) saturate(0%) contrast(1000%);
                }

                &:hover {
                    text-decoration-color: #ffffff;
                }
            }

            &.color-dark-gray {
                color: #4A4A4A;

                &:hover {
                    text-decoration-color: #4A4A4A;
                }
            }


            &:visited {
                &.color-white {
                    color: #ffffff;
                }

                &.color-dark-gray {
                    color: #4A4A4A;
                }
            }
        }
    }
}

.banner-image {
    background-image: $bg-img;

    &.crop-right {
        background-position: left;
        object-position: left;
        // polyfill for object-fit
        font-family: 'object-fit: cover; object-position: left;';
    }

    &.crop-left {
        background-position: right;
        object-position: right;
        // polyfill for object-fit
        font-family: 'object-fit: cover; object-position: right;';
    }

    &.crop-center {
        background-position: center;
        object-position: center;
        // polyfill for object-fit
        font-family: 'object-fit: cover; object-position: center;';
    }

    @media all and (min-width: 576px) {
        height: 230px;
    }

    @media all and (min-width: 768px) {
        height: 332px;

        .banner-container {
            .h1 {
                font-size: 40px;
                line-height: 48px;
            }

            h4 {
                font-size: 20px;
                line-height: 22px;
            }

            &.home {
                .banner-contents {
                    margin-top: -73px; /*height of task bar*/
                }

                .h1 {
                    font-size: 40px;
                    line-height: 1;
                    margin-bottom: 7px;
                }

                a {
                    font-size: 20px;
                    line-height: 1.1;

                    .link-icon {
                        height: 17px;
                        width: 17px;
                    }
                }
            }

            .banner-form-container {
                padding: 40px 50px;
            }
        }

        .banner-image {
            height: 231px;
            background-position-x: 0px;

            &.home {
                background-position-x: right;
                height: 332px;
            }
        }
    }

    @media all and (min-width: 1024px) {
        height: 576px;

        .banner-container {
            .h1 {
                font-size: 55px;
                line-height: 58px;
            }

            h4 {
                font-size: 26px;
                line-height: 28px;
            }

            &.home {
                .banner-contents {
                    margin-top: -110px; /*height of task bar*/
                }

                .h1 {
                    font-size: 66px;
                    line-height: 0.91;
                    margin-bottom: 10px;
                }

                a {
                    font-size: 28px;
                    line-height: 1.07;

                    .link-icon {
                        height: 20px;
                        width: 20px;
                    }
                }
            }

            .visual-callout {
                .visual-callout-content {
                    left: 30px;
                }
            }
        }

        .banner-image {
            height: 308px;

            &.home {
                background-position-x: -715px;
                height: 576px;
            }
        }
    }

    @media all and (min-width: 1280px) {
        .banner-container {
            .h1 {
                font-size: 66px;
                line-height: 68px;
            }

            h4 {
                font-size: 28px;
                line-height: 30px;
            }

            &.home {
                .h1 {
                    font-size: 66px;
                    line-height: 0.91;
                    margin-bottom: 10px;
                }

                a {
                    font-size: 28px;
                    line-height: 1.07;
                }
            }

            .visual-callout {
                .visual-callout-content {
                    left: 40px;
                }
            }
        }

        .banner-image {
            height: 438px;

            &.home {
                height: 576px;
                background-position-x: right;
            }
        }
    }
}
