﻿@import '../variables/_vars-nav.scss';

//----------------------------------------------
// NAVIGATION
//----------------------------------------------
.aep-brand-image {
    height: 44px;
    width: auto;
}

.navbar-toggler {
    border: none;
    border-radius: 0;
    padding: 0
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml, #{$aep-toggler-icon}");
}

.navbar-light .navbar-toggler {
    color: transparent;
    border-color: transparent;
}

.aep-navbar {
    position: relative;
    background-color: $white;
    border-bottom: solid 5px $red;
    padding-left: 0;
    padding-right: 0;
    z-index: 15;

    .container {
        justify-content: unset;
    }

    a {
        text-decoration: none;
        text-decoration-color: transparent;

        &:hover {
            text-decoration: none;
        }
    }

    .navbar-brand {
        margin-right: auto;
    }
}

.navbar-expand-md.navbar-nav.nav-link,
.navbar-light .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
}

.nav-link {
    span {
        color: $dark-gray;
        font-weight: 600;
        text-align: right;
        text-transform: uppercase;
        border-bottom: 2px solid transparent;
        line-height: normal;
        transition: all .25s;

        &:hover {
            border-bottom: 2px solid $dark-gray;
        }
    }
}

.nav-item {
    .nav-link {
        &.show {
            span {
                border-bottom: 2px solid $dark-gray;
            }

            &:after {
                bottom: 100%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(218, 41, 28, 0);
                border-bottom-color: $red;
                border-width: 20px;
                margin-left: -20px;
                top: 75%;
                left: 50%;

                @media all and (min-width:map-get($grid-breakpoints, "lg")) {
                    top: 100%;
                }
            }
        }
    }
}

.aep-login-link,
.aep-login-link-sm {
    display: none;
    float: right;
    height: 42px;
    width: 39px;
}

.aep-login-link-sm {
    display: block;
    margin-right: 30px;
    margin-left: auto;
}

.icon-login {
    height: 100%;
    width: 100%;
    font-family: $font-family-base;
}

.btn.btn-global-login, .logout-desktop {
    display: none;
}

.btn.btn-danger.btn-global-login, .btn.btn-danger.btn-global-logout {
    font-weight: 600;
    border-radius: 8px;
    font-size: 17px;
    margin-left: -5px;
    margin-right: 35px;
    padding: 0 27px;
    color: white;
}

.aep-nav-search {
    float: right;
    height: 21px;
    width: 21px;
}

/******************************************************************/
/* BREADCRUMBS */
.aep-breadcrumb-nav {
    background-color: $red;
    margin-top: 0;
    display: none;
}

.aep-breadcrumb {
    background-color: transparent;
    border-radius: 0;
}

.aep-breadcrumb-item > a {
    color: $white;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    span {
        border-bottom: solid 1px transparent;
        transition: all .25s;

        &:hover {
            border-bottom: solid 1px $white;
            text-decoration: none;
        }
    }
}

.aep-breadcrumb-item + .aep-breadcrumb-item::before {
    color: $white;
}
/***************************/
/* END BREADCRUMBS */

/* BANNER */
.banner-container {
    h1, h4 {
        color: #fff !important;
        text-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
    }

    h1 {
        font-size: 36px;
        line-height: 38px;
    }

    h4 {
        line-height: 20px;
        font-weight: 300;
    }

    .banner-form-container {
        background-color: rgba(255, 255, 255, 0.75);
        padding: 30px;
    }
}

.banner-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    height: 187px;
    overflow: hidden;
}

.banner-image-with-form {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
/***************************/
/* END BANNER */

.navbar-expand-md > .container {
    padding-left: 20px;
    padding-right: 20px;
}
// adjust container padding inside navbar
@media (min-width: map-get($grid-breakpoints, "xs")) {
    .navbar-expand-md > .container {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
    .navbar-expand-md > .container {
        padding-right: 50px;
        padding-left: 50px;
    }
}

@media (min-width: map-get($grid-breakpoints, "xl")) {
    .navbar-expand-md > .container {
        padding-right: 70px;
        padding-left: 70px;
    }
}

@media all and (min-width:map-get($grid-breakpoints, "sm")) {
    .aep-brand-image {
        height: 52px;
    }

    .aep-navbar {
        margin-bottom: 0;
        //border-bottom: none;
    }

    .aep-login-link-sm {
        display: block;
        margin-right: 52px;
        margin-left: auto;
    }

    .aep-breadcrumb-nav {
        display: block;
        height: 45px;
        border-top: solid 5px $red;
    }

    .aep-breadcrumb {
        margin: 0;
        padding: 5px 0 0;
    }

    .aep-breadcrumb-item {
        font-size: 16px;
    }

    .banner-image {
        height: 173px;
    }
}

@media all and (min-width:map-get($grid-breakpoints, "md")) {
    .aep-navbar {
        height: 100px; //100px?
        padding: 0;
    }

    .nav-link {
        font-size: 16px;
        margin-right: 20px;
    }

    .aep-login-link {
        display: block;
        margin-right: 10px;
    }

    .aep-login-link-sm {
        display: none;
    }


    .banner-container {
        h1 {
            font-size: 48px;
            line-height: 52px;
        }

        h4 {
            font-size: 22px;
            line-height: 24px;
        }

        .banner-form-container {
            padding: 40px 50px;
        }
    }

    .banner-image {
        height: 231px;
    }

    .logout-mobile {
        display: none;
    }

    .logout-desktop {
        display: inline-block;
    }
}

@media all and (min-width:map-get($grid-breakpoints, "lg")) {
    .aep-brand-image {
        height: 62px;
    }

    .aep-navbar {
        height: 125px;
    }

    .nav-link {
        font-size: 18px;
        margin-right: 30px;
    }

    .aep-login-link {
        display: none;
    }

    .btn.btn-danger.btn-global-login {
        display: inline-block;
    }

    .aep-nav-search {
        height: 23px;
        width: 23px;
    }

    .aep-breadcrumb {
        padding: 0.75rem 0;
    }

    .aep-breadcrumb-nav {
        height: 60px;
    }

    .aep-breadcrumb-item {
        font-size: 18px;
    }

    .banner-container {
        h1 {
            font-size: 66px;
            line-height: 68px;
        }

        h4 {
            font-size: 28px;
            line-height: 30px;
        }

        .visual-callout {
            .visual-callout-content {
                left: 30px;
            }
        }
    }

    .banner-image {
        height: 308px;
    }


    .banner-container {

        .banner-form-container {
            padding: 50px;
        }
    }
}

@media all and (min-width: 1100px) {
    .nav-link {
        font-size: 17px;
    }
}

@media all and (min-width: map-get($grid-breakpoints, "xl")) {
    .aep-brand-image {
        height: 70px;
    }

    .aep-navbar {
        height: 125px;
    }

    .nav-link {
        font-size: 17px;
        margin-right: 55px;
    }

    .banner-container {
        .visual-callout {
            .visual-callout-content {
                left: 40px;
            }
        }
    }

    .banner-image {
        height: 438px;
    }
}

// MEGA menu
// get rid of pseudo element on dropdown in navbar only
.navbar {
    .dropdown-toggle {
        position: relative;

        &:after {
            content: none;
        }
    }
}

.dropdown-container {
    margin: 0 0 30px 0;
    max-width: 230px;

    a.dropdown-item {
        &:hover {
            text-decoration: underline;
        }
    }
}

.dropdown-menu {
    background: $light-gray;
    border-top: 10px solid $red;
    border-bottom: 2px solid $dark-gray;
    border-radius: 0;
    box-shadow: 0px 5px 10px 0px rgba(74,74,74,0.5);
    padding: 30px 50px;
    width: 100%;

    @media all and (min-width:map-get($grid-breakpoints, "lg")) {
        padding: 50px;
    }

    @media all and (min-width:map-get($grid-breakpoints, "xl")) {
        padding: 50px 70px;
    }
    /*[class*="col-"] {
        max-width: 280px;
        padding-left: 40px;
        padding-right: 40px;
    }*/
    .sub-title {
        a {
            color: $red;
            font-size: 1.25rem;
            font-weight: bold;
        }

        a.dropdown-item {
            padding-bottom: 3px;
        }
    }

    .dropdown-item {
        color: $dark-gray;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.25;
        padding: 0 0 12px 0;
        white-space: normal;

        &:active {
            background-color: transparent;
        }

        @media all and (min-width:map-get($grid-breakpoints, "lg")) {
            font-size: 1.25rem;
        }
    }
}

.btn-global-login, .btn-global-logout, .aep-login-link, .aep-login-link-sm {
    position: relative;

    &.login-expanded:after {
        bottom: 100%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(218, 41, 28, 0);
        border-bottom-color: #DA291C;
        border-width: 40px;
        margin-left: -43px;
        top: 20%;
        left: 50%;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            top: 25%;
        }

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            top: 40%;
        }

        @media all and (min-width:map-get($grid-breakpoints, "lg")) {
            margin-left: -40px;
            top: 50%;
        }
    }
}

//AGENCY NAVBAR & DROPDOWN
.agency-header .navbar {
    padding-left:20px;
}

.agency-navbar{
    a{
        cursor: pointer;
    }
    .aep-login-link-sm{
        margin-right: 0;
    }
    .dropdown-menu{
        border:none;
        border-top:solid 5px $red;
        box-shadow: 0px 2px 2px 0px rgba(74,74,74,0.3);
        left: -15px;
        padding:10px 15px;
        width:auto;

        .nav-link{
            margin-right: 0;

            &span{
                &:focus, &:active, &:hover{
                    border-bottom:2px solid $dark-gray;
                }
            }
        }
        .nav-link span{
            font-weight: normal;
            text-transform: none;
        }

        @media all and (min-width:map-get($grid-breakpoints, "sm")){
            top:78px;
        }

        @media all and (min-width:map-get($grid-breakpoints, "md")){
            top:65px;
        }

        @media all and (min-width:map-get($grid-breakpoints, "lg")){
            top:78px;
        }
    }
}