﻿@import '../variables/_vars-tables.scss';

//----------------------------------------------
// TABLES
//----------------------------------------------
.table {
    margin-bottom:0;

    thead {
        th {
            vertical-align: top;
        }
    }
}
.table-striped {
    /*
        Not sure about the 2px bottom border. This will make it 1px
        thead {
        th, td {
            border-bottom-width: $table-border-width;
        }
    }*/

    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $table-accent-bg;
    }
}

.table-row-dividers {
    color:$dark-gray;

    tbody {
        tr {
            border-bottom:1px solid #979797;
        }
    }

    th {
        font-size:0.875rem;
        font-weight:bold;
        padding:0 5px;
    }
    td {
        font-size:1rem;
        padding: 23px 5px;
    }
    @media all and (min-width:map-get($grid-breakpoints, "md")) {
        th {
            font-size:1.25rem;
        }
        td {
            font-size:1.125rem;
        }

    }
}

.table-stacked {
    h4 {
        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            font-size: 1.25rem;
            font-weight: 300;
        }
    }

    thead {
        display: none;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            display: table-header-group;
        }
    }

    tbody tr {
        border: 1px solid $light-gray;
        display: block;
        margin: 0 0 10px 0;
        padding: 30px 20px;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            display: table-row;
            padding: inherit;
        }
    }

    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $white;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            background-color: $table-accent-bg;
        }
    }

    tbody tr:nth-of-type(even) {
        background-color: $light-gray;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            background-color: inherit;
        }
    }

    &.table-bordered {
        border: none;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            border: 1px solid $mid-gray;
        }

        td {
            border: none;

            @media all and (min-width:map-get($grid-breakpoints, "md")) {
                border: 1px solid $mid-gray;
            }
        }
    }

    td {
        display: block;

        @media all and (max-width: 767px) {
            padding: 25px 0 30px;
            position: relative;

            &:before {
                position: absolute;
                top: 0;
                font-size: 18px;
                font-weight: 600;
                left: 0;
                line-height: 1.1;
                content: attr(data-label);
            }

            &:last-child {
                padding: 25px 0 0 0;
            }

            h4 {
                font-size: 1rem;
                font-weight: 300;
            }
        }


        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            display: table-cell;
        }
    }
}

// AGENCY material-table overrides
.material-table-css {
    .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
        display: none;
    }
    .MuiPaper-root.MuiPaper-elevation2, .MuiPaper-root{
        box-shadow: none;
    }
    .MuiToolbar-root .MuiToolbar-regular .MTableToolbar-root-5 .MuiToolbar-gutters{
        width:fit-content;
    }
    .MuiTableSortLabel-icon {
        opacity: 1;
        font-size: 12px;
    }
    .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
        font-weight: 700;
        font-size: 18px;
        color: #025A95;
    }
    .MuiTableSortLabel-root.Mui-active, .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
        color: #025A95;
    }
    [data-rbd-drag-handle-context-id="1"] {
        cursor: pointer;
    }
    tbody {
        .MuiTableRow-root {
            height: 0 !important;
            &.MuiTableRow-hover {
                height: auto !important;
            }
        }
    }
}

.agency-profile-table{
     border:solid 1px #dcdcdc;

    .row{
        border-top:solid 1px #dcdcdc;
        margin:0;
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .row:first-child{
        border-top:none;
    }
    .row:last-child{
        border-bottom: none;
    }
}

.accordion-wrapper {
    .MuiAccordionDetails-root {
        flex-wrap: wrap;
        padding: 8px 0;
        margin-left: 16px;
    }
    .MuiPaper-elevation1 {
        box-shadow: 0px 2px 1px -1px #000, 0px 1px 1px 0px #000, 0px 1px 3px 0px #000;
        &:hover {
            color: $dark-blue;
            background-color: #eee;
        }
        &.Mui-expanded:hover {
            background-color: #fff;
            color: #000;
        }
    }
    .sub-accordion-custom {
        font-size: 18px;
        &:hover {
            color: $dark-blue;
            background-color: #eee;
        }
    }
    .MuiAccordionSummary-content {
        font-weight: 400;
        color: $blue;
        &.Mui-expanded {
            font-weight: 700;
            color: $dark-blue;
            .sub-location-aligment {
                bottom: 15px;
            }
        }
    }
    .sub-location-aligment {
        font-size: 1.25rem;
        position: absolute;
        right: 50px;
        bottom: 6px;
        &:hover {
            text-decoration: underline;
            color: #000;
        }
    }
    .agency-name-update:hover {
        text-decoration: underline;
        color: #000;
    }
}