//----------------------------------------------
// PROMOS
//----------------------------------------------

.visual-callout {
    display: block;
    position: relative;

    .visual-image-container {
        min-height: 230px;
        background-size: cover;
        background-position-x: -80px;
        overflow: hidden;
        background-repeat: no-repeat;

        @media all and (min-width:map-get($grid-breakpoints, "sm")) {
            min-height: 180px;
            max-width: none;
            background-position-x: 0px;
        }

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            min-height: initial;
        }
    }

    .visual-callout-content {
        display: block;
        margin-top: 20px;

        .link-icon {
            display: inline-block;
            background-image: url("data:image/svg+xml, #{$chevron-right}");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            height: 17px;
            width: 17px;
            margin-left: 5px;
        }

        .visual-callout-description {
            .link-icon {
                display: none;
            }
        }

        .visual-callout-title, .visual-callout-description, .visual-callout-label {
            display: block;
            font-weight: $font-weight-bold;
            color: $dark-gray;
            font-size: 1.5rem;
            line-height: 1.08;
            text-transform: uppercase;
        }

        .visual-callout-description, .visual-callout-label {
            margin-top: 10px;
            font-weight: $font-weight-normal;
            line-height: 1.06;
            font-size: 1.125rem;
            text-transform: none;
        }

        @media all and (min-width:map-get($grid-breakpoints, "sm")) {
            margin-top: 0;
            display: flex;
            align-items: center;
            position: absolute;
            left: 55%;
            right: 20px;
            top: 0;
            bottom: 0;

            .visual-callout-description {
                .link-icon {
                    display: inline-block;
                }
            }

            .visual-callout-title {
                font-weight: $font-weight-bolder;

                .link-icon {
                    display: none;
                }
            }

            .link-icon {
                height: 14px;
                width: 14px;
            }
        }

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            right: 40px;
        }

        @media all and (min-width:map-get($grid-breakpoints, "lg")) {
            .visual-callout-title {
                font-size: 2rem;
                line-height: 1.09;
            }

            .visual-callout-description, .visual-callout-label {
                line-height: 1.08;
                font-size: 1.5rem;
            }

            .link-icon {
                height: 16px;
                width: 16px;
            }
        }

        @media all and (min-width:map-get($grid-breakpoints, "xl")) {
            right: 80px;

            .visual-callout-title {
                font-size: 3rem;
                line-height: 1.13;
            }

            .visual-callout-description, .visual-callout-label {
                margin-top: 15px;
                line-height: 1.11;
                font-size: 2.25rem;

                .link-icon {
                    height: 24px;
                    width: 24px;
                    margin-left: 8px;
                }
            }
        }
    }

    &:hover {
        text-decoration: none;

        .visual-callout-content {

            .visual-callout-description {
                text-decoration: underline;
            }

            .link-icon {
                background-image: url("data:image/svg+xml, #{$chevron-right-hover}");
            }
        }
    }

    &.left {
        .visual-image-container {
            background-position-x: -340px;

            @media all and (min-width:395px) {
                background-position-x: -180px;
            }

            @media all and (min-width:450px) {
                background-position-x: -140px;
            }


            @media all and (min-width:map-get($grid-breakpoints, "sm")) {
                background-position-x: 0px;
            }
        }

        .visual-callout-content {
            @media all and (min-width:map-get($grid-breakpoints, "sm")) {
                left: 20px;
                right: 50%;
            }

            @media all and (min-width:map-get($grid-breakpoints, "md")) {
                left: 40px;
            }

            @media all and (min-width:map-get($grid-breakpoints, "xl")) {
                left: 80px;
            }
        }
    }

    &.half {
        .visual-image-container {
            background-position-x: 0px;

            @media all and (min-width:map-get($grid-breakpoints, "md")) {
                min-height: 250px;
            }

            @media all and (min-width:map-get($grid-breakpoints, "lg")) {
                max-width: none;
                min-height: initial;
            }
        }

        .visual-callout-content {
            .link-icon {
                height: 17px;
                width: 17px;
            }

            .visual-callout-description {
                .link-icon {
                    display: none;
                }
            }

            .visual-callout-title {
                .link-icon {
                    display: inline-block;
                }
            }

            @media all and (min-width:map-get($grid-breakpoints, "sm")) {
                display: block;
                position: relative;
                left: 0;
                margin-top: 20px;

                .visual-callout-title {
                    font-size: 1.5rem;
                    line-height: 1.08;
                }

                .visual-callout-description, .visual-callout-label {
                    font-size: 1.125rem;
                    line-height: 1;
                }
            }

            @media all and (min-width:map-get($grid-breakpoints, "lg")) {
                right: 15px;
                margin-top: 0;
                display: flex;
                position: absolute;
                left: 55%;

                .visual-callout-description {
                    .link-icon {
                        display: inline-block;
                    }
                }

                .visual-callout-title {
                    font-weight: $font-weight-bolder;

                    .link-icon {
                        display: none;
                    }
                }

                .link-icon {
                    height: 14px;
                    width: 14px;
                }
            }

            @media all and (min-width:map-get($grid-breakpoints, "xl")) {
                right: 30px;

                .visual-callout-title {
                    font-size: 2rem;
                    line-height: 1.09;
                }

                .visual-callout-description, .visual-callout-label {
                    font-size: 1.5rem;
                    line-height: 1.08;
                }

                .link-icon {
                    height: 17px;
                    width: 17px;
                }
            }
        }
    }

    &.white {
        @media all and (min-width:map-get($grid-breakpoints, "sm")) {
            .visual-callout-title, .visual-callout-description, .visual-callout-label {
                color: $white;
            }
        }

        &.half {
            .visual-callout-title, .visual-callout-description, .visual-callout-label {
                color: $dark-gray;
            }

            @media all and (min-width:map-get($grid-breakpoints, "lg")) {
                .visual-callout-title, .visual-callout-description, .visual-callout-label {
                    color: $white;
                }
            }
        }
    }


    &.dark-blue {
        @media all and (min-width:map-get($grid-breakpoints, "sm")) {
            .visual-callout-title {
                color: $dark-blue;
            }
        }

        &.half {
            .visual-callout-title {
                color: $dark-gray;
            }

            @media all and (min-width:map-get($grid-breakpoints, "lg")) {
                .visual-callout-title {
                    color: $dark-blue;
                }
            }
        }
    }
}


//------------------------------------------
// Icon Call Out----------------------------
.icon-callout {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    a.h2 {
        margin-bottom: 0;
        text-decoration: underline;
        text-decoration-color: transparent;

        &:hover {
            text-decoration: underline;
            text-decoration-color: inherit;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg")) {
            font-size: 1.875rem; //30px
            line-height: 1.2667; //38px
        }

        @media all and (min-width: map-get($grid-breakpoints, "xl")) {
            font-size: 2.25rem; //36px
            line-height: 1.0556; //38px
        }
    }

    .h4 {
        font-size: 1rem; //16px
        font-weight: $font-weight-light;
        line-height: 1.25; //20px
        @media all and (min-width: map-get($grid-breakpoints, "sm")) {
            font-size: 1.125rem; //18px
            line-height: 1.2222; //22px
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg")) {
            font-size: 1.375rem; //22px
            line-height: 1.1818; //26px
        }

        @media all and (min-width: map-get($grid-breakpoints, "xl")) {
            font-size: 1.5rem; //24px
            line-height: 1.1818; //26px
        }

        p {
            font-size: 1rem;

            @media all and (min-width: map-get($grid-breakpoints, "sm")) {
                font-size: 1.125rem;
            }

            @media all and (min-width: map-get($grid-breakpoints, "lg")) {
                font-size: 1.25rem;
                line-height: 1.5;
            }
        }
    }

    .ic-btn-container {
        text-align: left;

        @media all and (min-width: map-get($grid-breakpoints, "md")) {
            text-align: center;
        }
    }


    a[class*="ai-"] {
        background-position: center;
        display: inline-block;
        height: 40px;
        width: 40px;

        @media all and (min-width: map-get($grid-breakpoints, "sm")) {
            height: 50px;
            width: 50px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg")) {
            height: 60px;
            width: 60px;
        }
    }
}

//------------------------------------------
// Tertiary Promos -------------------------
.tp {
    .tp-image-container {
        margin-bottom: 20px;
    }

    .tp-info {
        padding-right: 0;
        padding-left: 0;

        h3 {
            margin-bottom: 10px;

            @media all and (min-width:map-get($grid-breakpoints, "sm")) {
                margin-bottom: 1.25rem;
            }
        }
    }

    .tp-image-caption {
        color: $dark-gray;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.25
    }

    .tp-text {
        color: $dark-gray;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: calc(0.75em + 7px);
    }
    // tablet large (IPad Pro)
    @media all and (min-width:map-get($grid-breakpoints, "md")) {
        .tp-info {
            padding-right: 0;
            padding-left: 0;
        }

        .tp-image-caption {
            font-size: 24px;
            line-height: 1.17;
        }

        .tp-text {
            font-size: 18px;
            line-height: 1.56;
        }
    }
    // desktop
    @media all and (min-width: map-get($grid-breakpoints, "lg")) {
        .tp-image-caption {
            font-size: 28px;
            line-height: 1.13;
        }

        .tp-text {
            font-size: 20px;
            line-height: 1.5;
        }
    }
}

// TASK BAR
.shrink-wrap-text {
    width: min-content;
}

.task-bar {
    display: flex;
    flex-direction: column;
    border-top: solid 10px $gray-100;
    border-bottom: solid 10px $gray-100;
    margin-bottom: 30px;

    .task-bar-item {
        padding: 0 16px;
        height: 80px;
        display: flex;
        flex-grow: 1;
        justify-content: center;

        a {
            display: flex;
            align-items: center;
            font-size: 1.125rem;
            font-weight: $font-weight-bolder;
            line-height: 1.22;
            background-image: url("data:image/svg+xml, #{$chevron-right}");
            background-size: 17px;
            background-position: right center;
            background-repeat: no-repeat;
            width: 100%;
            padding-right: 30px;
            text-transform:uppercase;

            span[class*="ai-"] {
                width: 30px;
                height: 30px;
                min-width: 30px;
                margin-right: 17px;
            }

            color: $dark-gray;

            &:hover {
                text-decoration-color: $dark-gray;
            }

            @media all and (min-width:map-get($grid-breakpoints, "sm")) {
                background-size: 22px;
            }
        }
    }

    &:not(.home) .task-bar-item {
        a .shrink-wrap-text {
            width: auto;
        }
    }

    &.home {
        border-top: 0;
        border-bottom: 0;
        margin-bottom: 0;

        .task-bar-item {
            flex-grow: 1;
            justify-content: center;

            a {
                font-weight: 600;
            }
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "sm")) {
        .task-bar-item a span[class*="ai-"] {
            width: 36px;
            height: 36px;
        }
    }

    .task-divider {
        height: 10px;
        background-color: $gray-100;
    }

    @media all and (min-width:680px) {
        border-top-width: 15px;
        border-bottom-width: 15px;
        margin-bottom: 40px;
        padding: 25px 0;
        flex-direction: row;


        .task-bar-item {
            padding: 0 28px;

            a {
                padding-right: 0;
                width: min-content;
                background-image: none;

                span[class*="ai-"] {
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    margin-right: 20px;
                }
            }
        }


        &.home {
            padding: 5px 0;
            border-top: 0;
            border-bottom: 0;
            margin-bottom: 0;

            .task-bar-item {
                padding: 0 20px;
                height: 63px;

                a {
                    width: auto;
                    font-size: 16px;
                    line-height: 1.15;
                    padding-right: 13px;
                }

                span[class*="ai-"] {
                    width: 28px;
                    height: 28px;
                    min-width: 28px;
                    margin-right: 10px;
                }
            }
        }

        .task-divider {
            height: auto;
            width: 1px;
            margin: 0 auto;
            background-color: #979797;
        }
    }

    @media all and (min-width: 900px) {
        &.no-shrink-900 .task-bar-item {
            flex-grow: 0;

            a {
                width: auto;

                .shrink-wrap-text {
                    width: auto;
                }
            }
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "lg")) {
        border-top-width: 20px;
        border-bottom-width: 20px;
        margin-bottom: 40px;
        padding: 30px 0;

        .task-bar-item {
            padding: 0 28px;

            a {
                font-size: 1.375rem;

                span[class*="ai-"] {
                    width: 41px;
                    height: 41px;
                    min-width: 41px;
                }
            }
        }

        &.home {
            padding: 15px 0;
            border-top: 0;
            border-bottom: 0;
            margin-bottom: 0;

            .task-bar-item {
                padding: 0 25px;
                height: 80px;

                a {
                    font-size: 22px;
                    line-height: 1.15;
                }

                span[class*="ai-"] {
                    width: 38px;
                    height: 38px;
                    min-width: 38px;
                    margin-right: 16px;
                }
            }
        }
    }

    @media all and (min-width: 1100px) {
        &.no-shrink-1100 .task-bar-item {
            flex-grow: 0;

            a {
                width: auto;

                .shrink-wrap-text {
                    width: auto;
                }
            }
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "xl")) {
        margin-bottom: 60px;

        &.home {
            margin-bottom: 0;
        }

        &.shrink-xl .task-bar-item {
            flex-grow: 1;

            a {
                width: min-content;

                .shrink-wrap-text {
                    width: min-content;
                }
            }
        }

        .task-bar-item {
            padding: 0 46px;

            a {
                font-size: 1.5rem;

                span[class*="ai-"] {
                    width: 60px;
                    height: 60px;
                    min-width: 60px;
                }
            }
        }

        &.home {
            padding: 15px 0;
            border-top: 0;
            border-bottom: 0;
            margin-bottom: 0;

            .task-bar-item {
                padding: 0 39px;
                height: 100px;

                a {
                    font-size: 28px;
                    line-height: 1.15;
                }

                span[class*="ai-"] {
                    width: 48px;
                    height: 48px;
                    min-width: 48px;
                    margin-right: 21px;
                }
            }
        }
    }

    @media all and (min-width: 1350px) {
        &.no-shrink-1350 .task-bar-item {
            flex-grow: 0;

            a {
                width: auto;

                .shrink-wrap-text {
                    width: auto;
                }
            }
        }
    }
}
