﻿@import '../variables/_vars-tables.scss';

//----------------------------------------------
// INFOGRAPHICS
//----------------------------------------------

.info-graphic-wrapper {
    position: relative;
    font-weight: $font-weight-bold;

    .igw-col {
        margin-bottom: 30px;
        min-height: 50px;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            min-height: 90px;
        }
    }

    .igw-number {
        font-size: 26px;
        color: $white;
        height: 39px;
        width: 39px;
        border-radius: 30px;
        background-color: $blue;
        display: inline-block;
        text-align: center;
        position: absolute;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            font-size: 30px;
            height: 45px;
            width: 45px;
        }

        @media all and (min-width:map-get($grid-breakpoints, "lg")) {
            font-size: 40px;
            height: 60px;
            width: 60px;
        }
    }

    .igw-text {
        font-size: 18px;
        padding-left: 60px;
        display: inline-block;

        @media all and (min-width:map-get($grid-breakpoints, "md")) {
            font-size: 20px;
        }

        @media all and (min-width:map-get($grid-breakpoints, "lg")) {
            padding-left: 80px;
        }
    }
}