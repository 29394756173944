﻿/* variables used throughout our scss AND bootstrap variables */


//----------------------------------------------
// TYPOGRAPHY 
//----------------------------------------------

$browser-context: 16; // base font size
$font-family-base: din-2014, Arial, Roboto, sans-serif;  // bootstrap
$line-height-base: 1.5;    // bootstrap
$line-height-normal: 1.3;
$font-size-base: 1rem;


$font-weight-base: 300;
$font-weight-light: 300;
$font-weight-normal: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;


$body-color: $dark-gray;    
$headings-color: $red;

$headings-line-height: 1.125;  // bootstrap
$headings-font-weight: $font-weight-bolder;  // bootstrap

$link-color: $dark-blue;

// spacing
$paragraph-margin-bottom: 0.75em;
$headings-margin-bottom: 0.375em;
$headings-uppercase-margin-bottom: 0.25em;


// issues with resizing from 20px down to 16px
/*$enable-responsive-font-sizes: true; // rfs plugin
$rfs-base-font-size: 1rem;
$rfs-factor: 10;  // Factor of decrease*/
