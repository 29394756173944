﻿//----------------------------------------------
// IMAGES
//----------------------------------------------

img{
    max-width:100%;
}

img.fw-image {
    object-fit: cover;
    min-height: 50px;
    max-width: 100%;
    height: auto;
    // polyfill for object-fit
    font-family: 'object-fit: cover; object-position: center;';

    &.crop-right {
        object-position: left;
        // polyfill for object-fit
        font-family: 'object-fit: cover; object-position: left;';
    }

    &.crop-left {
        object-position: right;
        // polyfill for object-fit
        font-family: 'object-fit: cover; object-position: right;';
    }
}
