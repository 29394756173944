$bg-img: url('../Images/banner/IMG-hug-Large.png');
#loginFormContainer{
    font-family: din-2014, Arial, Roboto, sans-serif;
    margin-bottom: -80px;
    input, h1, h4, p, span, label, .btn, .btn-secondary, .checkbox, .invalid-feedback{
        font-family: din-2014, Arial, Roboto, sans-serif;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #4A4A4A;
        font-family: din-2014, Arial, Roboto, sans-serif;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: #4A4A4A;
        font-family: din-2014, Arial, Roboto, sans-serif;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: #4A4A4A;
        font-family: din-2014, Arial, Roboto, sans-serif;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: #4A4A4A;
        font-family: din-2014, Arial, Roboto, sans-serif;
    }

    .login-bg-image{
        background-size: cover;
        object-fit: cover;
        height: 100%;
        // polyfill for object-fit
        font-family: 'object-fit: cover; object-position: center;';

        &.crop-right {
            background-position: left;
            object-position: left;
            // polyfill for object-fit
            font-family: 'object-fit: cover; object-position: left;';
        }

        &.crop-left {
            background-position: right;
            object-position: right;
            // polyfill for object-fit
            font-family: 'object-fit: cover; object-position: right;';
        }

        &.crop-center {
            background-position: center;
            object-position:center;
            // polyfill for object-fit
            font-family: 'object-fit: cover; object-position: center;';
        }
    }

    .login-banner-container{
        font-family: din-2014, Arial, Roboto, sans-serif;
        .banner-form-container {
            background-color: rgba(255, 255, 255, 0.75);
            padding: 30px;
        }
        h1 {
            font-size: 2em!important;
            line-height: 1.0625!important;
            text-shadow:none;
        }

        h4 {
            font-size: 1.25em!important;
            font-weight: bold;
            line-height: 1.22!important;
            text-shadow:none;
        }
    }

    .form-red{
        padding: 20px 30px;
    }

    .btn.btn-secondary-white{
        padding:0 12px;
    }

    .white-border{
        height:1px;
        border-bottom:solid 1px #fff;
    }
    @media all and (min-width:576px) {
        .login-bg-image{
            background-image: $bg-img;
            height: 450px;
        }
        .form-red{
            padding: 20px 30px 30px;
        }
    }

    @media all and (min-width:768px) {
        .login-banner-container{
            .banner-form-container {
                padding: 40px 50px;
            }
        }
        .login-bg-image{
            height: 550px;
        }

        .btn.btn-secondary-white{
            padding:0 20px;
        }

        .footer-nav{
            margin-top: -45px!important;
        }
    }

    @media all and (min-width:1024px) {
        margin-bottom: -100px;
        .login-banner-container{
            .banner-form-container {
                padding: 50px;
            }
        }
        .login-bg-image{
            height: 650px;
        }

        .form-red{
            padding: 30px 45px;
        }

        .btn.btn-secondary-white{
            padding:0 25px;
        }
    }
}