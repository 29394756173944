/* our variables and overrides of bootstrap variables */
@import 'variables/_vars.scss';

/* import bootstrap grid reboot and all components by default, we can create our own bootstrap.scss file if we are not going to use a lot of the components */
@import "vendor/bootstrap/bootstrap.scss";
// TODO: before production release only include bootstrap components that are used
/*@import "../../node_modules/bootstrap/scss/bootstrap-grid";*/
/*@import "../../node_modules/bootstrap/scss/bootstrap-reboot";*/

// Material UI Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* our customer scss files below */

// ABSTACTS
@import 'abstracts/_functions';
@import 'abstracts/_mixins';

// LAYOUT
@import 'layout/_scaffolding.scss';
@import 'layout/_typography.scss';

@import 'layout/_footer.scss';
@import 'layout/_header.scss';
@import 'layout/_navigation.scss';


// COMPONENTS
@import './components/_accordions.scss';
@import './components/_account-block.scss';
@import 'components/_alerts.scss';
@import 'components/_blockquotes.scss';
@import 'components/_borders.scss';
@import 'components/_buttons.scss';
@import 'components/_cards.scss';
@import 'components/_carousel.scss';
@import 'components/_slideshow.scss';
@import 'components/_context-navigation.scss';
@import 'components/_forms.scss';
@import 'components/_images.scss';
@import 'components/_infographics.scss';
@import 'components/_lists.scss';
@import 'components/_modals.scss';
@import 'components/_panels.scss';
@import 'components/_promos.scss';
@import 'components/_searchbar.scss';
@import 'components/_svgs.scss';
@import 'components/_tabs.scss';
@import 'components/_tables.scss';
@import 'components/_progress-indicator.scss';

// PAGE SPECIFIC
@import 'pages/account/bills/pay/auto-pay.scss';
@import 'pages/news.scss';
@import 'pages/allconnect.scss';

// FONT
@import url('https://use.typekit.net/oqp1mrw.css');