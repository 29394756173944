﻿@import '../variables/_vars-lists.scss';

//----------------------------------------------
// LISTS
//----------------------------------------------
ol, ul, dl {
    margin-bottom: 0.75em;
}

ol li {
    margin-bottom: 10px;

    ol {
        li {
            list-style-type: disc;
        }
    }
}

.no-margins {
    margin-left: 0;
    padding-left: 0;

    li {
        margin-left: 21px;
    }
}

.list-header {
    margin: 30px 0;
}

.bulleted-list, .disc {
    font-size: 1rem;
    font-weight: $font-weight-light;
    line-height: 24px;
    padding: 0 0 0 35px;

    @media all and (min-width: map-get($grid-breakpoints, "sm")) {
        font-size: 1.125rem;
        line-height: 26px;
        padding: 0 0 0 55px;
    }

    @media all and (min-width: map-get($grid-breakpoints, "lg")) {
        font-size: 1.25rem;
        line-height: 30px;
        padding: 0 0 0 60px;
    }


    &.no-indent {
        padding: 0 0 0 17px;

        @media all and (min-width: map-get($grid-breakpoints, "sm")) {
            padding: 0 0 0 20px;
        }
    }

    &.no-indent-mobile {
        padding: 0 0 0 17px;

        @media all and (min-width: map-get($grid-breakpoints, "sm")) {
            padding: 0 0 0 20px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "md")) {
            padding: 0 0 0 55px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg")) {
            padding: 0 0 0 60px;
        }
    }

    ul li {
        list-style: none;
        margin-bottom: 10px;
        padding: 0 0 0 5px;
        position: relative;

        &:before {
            content: '●';
            font-size: 1.094rem;
            left: -1em;
            position: absolute;
            top: -1px;
        }
    }

    ol li {
        list-style: none;
        margin-bottom: 10px;
        padding: 0 0 0 5px;
        position: relative;
    }
}

.bold-list-style {
    counter-reset: item;
    list-style: none;
    margin-left: 5px;

    li {
        counter-increment: item;

        &:before {
            content: counters(item, ".") ". ";
            font-weight: bold;
            margin-left: -26px;
            margin-right: 5px;
        }
    }
}


// Link Lists ----------------------------------
// ---------------------------------------------
.link-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        font-size: 18px;
        line-height: normal;
        margin-bottom: 19px;
        padding-bottom: 19px;
        clear: both;
        border-bottom: solid 2px $icon-gray;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: $dark-gray;
            text-decoration: none;
            transition: all .25s;

            &.link-list-text {
                background-image: url("data:image/svg+xml, #{$chevron-right}");
                fill: rgb(175, 34, 24);
                background-size: 20px 20px;
                background-position-x: calc(100% - 1px);
                background-position-y: center;
                background-repeat: no-repeat;
                display: block;
                padding-right: 30px;
                padding-bottom: 0;
                margin-bottom: 0;
                margin-right: 0;
                //transition: all .25s;
                p {
                    margin: 0;
                    text-decoration: solid underline;
                    text-decoration-color: transparent;
                    transition: all .25s;

                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: $dark-gray;
                    }
                }

                &:hover {
                    background-image: url("data:image/svg+xml, #{$chevron-right-hover}");
                }
            }
        }

        &:first-child {
            /*padding-top: 19px;*/
        }
    }
}
// tablet small (iPad Mini)
@media all and (min-width:map-get($grid-breakpoints, "sm")) {
    .link-list li a {
        &.link-list-text {
            margin-left: 17px;
            padding-right: 35px;
        }
    }
}
// tablet large (IPad Pro)
@media all and (min-width:map-get($grid-breakpoints, "md")) {
    .link-list li {
        font-size: 22px;
        border-bottom: solid 1px $icon-gray;

        a {
            margin-bottom: 13px;
            padding-bottom: 16px;

            &.link-list-text {
                background-size: 25px 25px;
                margin-left: 27px;
                padding-right: 50px;
            }
        }
    }
}
// desktop
@media all and (min-width: map-get($grid-breakpoints, "lg")) {
    .link-list li {
        font-size: 24px;

        a {
            &.link-list-text {
                margin-left: 30px;
            }
        }
    }
}

// single link AKA "Featured Link"
.featured-link {
    border-bottom: solid 2px $icon-gray;
    clear: both;
    padding-bottom: 19px;

    a {
        color: $dark-gray;
        font-size: 18px;
        line-height: normal;
        margin-left: 20px;
        transition: all .25s;

        &.featured-link-text {
            background-image: url("data:image/svg+xml, #{$chevron-right}");
            fill: rgb(175, 34, 24);
            background-size: 20px 20px;
            background-position-x: calc(100% - 1px);
            background-position-y: center;
            background-repeat: no-repeat;
            display: block;
            padding-right: 30px;
            padding-bottom: 0;
            margin-bottom: 0;
            margin-right: 0;

            span {
                margin: 0;
                text-decoration: solid underline;
                text-decoration-color: transparent;
                transition: all .25s;
            }

            &:hover {
                text-decoration: underline;
                text-decoration-color: $dark-gray;
                background-image: url("data:image/svg+xml, #{$chevron-right-hover}");
            }
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "sm")) {
        a {
            margin-left: 17px;
            padding-right: 35px;
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "md")) {
        border-bottom: solid 1px $icon-gray;
        padding-bottom: 16px;

        a {
            font-size: 22px;
            margin-left: 27px;
            padding-right: 50px;


            &.featured-link-text {
                background-size: 25px 25px;
            }
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "lg")) {
        a {
            font-size: 24px;
            margin-left: 30px;
        }
    }
}
// end link lists


// Document Icon Lists -------------------------
// ---------------------------------------------
.document-icon-list {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        margin: 0 0 15px 0;

        &:last-child {
            margin: 0;
        }
    }
}
// global document icon bg
.document-icon {
    background-repeat: no-repeat;
    background-size: 35px 35px;
    background-position: left 3px;
    color: $gray-900;
    display: inline-block;
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
    padding: 7px 0 7px 45px;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all .25s;

    &.doc-icon-lg {
        font-size: 1.375rem;
        padding: 5px 0 5px 45px;


        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            font-size: 1.5rem;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            font-size: 1.75rem;
        }
    }

    @media all and (min-width: map-get($grid-breakpoints, "md") ) {
        font-size: 1.25rem;
    }

    @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
        font-size: 1.5rem;
        line-height: 1.175;
    }

    &:hover {
        color: $gray-900;
        text-decoration: underline;
        text-decoration-color: $gray-900;
        transition: all .25s;
    }
    &:visited{
        color: $dark-gray;
    }
}
// don't want this nested in case it is NOT a list; a one-off
.pdf-icon {
    // background-image: url('/lib/images/svgs/pdf-icon.svg');
}

.footer-pdf-icon {
    // background-image: url('/lib/images/svgs/footer-pdf.svg');
}
// ---------------------------------------------
// end Document Icon Lists ---------------------
