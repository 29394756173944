﻿//----------------------------------------------
// ACCORDIONS
//----------------------------------------------

.accordion {
    margin-bottom: 0px;

    dt,
    .table-accordion-header {
        background-color: $duckegg-blue;
        cursor: pointer;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        line-height: 1.15;
        margin-top: 10px;
        padding: 19px;

        &:first-child {
            margin-top: 0;
        }

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            font-size: 1.25rem;
            line-height: 1.3;
            padding: 15px 30px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            font-size: 1.75rem;
            line-height: 1.22;
            padding: 28px 50px;
        }

        &.collapsed {
            .plus-minus-toggle {
                &:after {
                    transform: rotate(90deg);
                }

                &:before {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .accordion-header {
        max-width: 97%;
        padding-right: 15px;
    }

    dd {
        margin-bottom: 0;

        .accordion-item {
            padding: 20px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            font-size: 1.25rem;

            .accordion-item {
                padding: 20px 30px 30px;
            }
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            .accordion-item {
                padding: 20px 50px;
            }
        }
    }

    p {
        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            font-size: 1.125rem;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            font-size: 1.25rem;
        }
    }
}

.plus-minus-toggle {
    cursor: pointer;
    display: inline-block;
    height: 15px;
    position: relative;
    width: 15px;

    @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
        height: 20px;
        width: 20px;
    }

    &:before,
    &:after {
        background: $gray-900;
        content: '';
        height: 4px;
        left: 0px;
        position: absolute;
        top: 5px;
        width: 15px;
        transition: transform 500ms ease;

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            height: 5px;
            top: 7px;
            width: 19px;
        }
    }

    &:after {
        transform-origin: center;
    }
}

//Accordion Tables
.table-accordion {
    .table-accordion-content {
        padding: 0;
    }

    .table-accordion-row {
        margin-bottom: 0;
        padding: 20px;

        @media all and (min-width: map-get($grid-breakpoints, "md") ) {
            font-size: 1.25rem;
            line-height: 1.2;
            padding: 20px 30px 30px;
        }

        @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
            padding: 30px 50px;
        }
    }
}

.table-accordion-row {
    &:nth-child(even) {
        background-color: $gray-100;
    }
}