﻿// PLEASE KEEP THIS IN ALPHABETICAL ORDER
.ai {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
}
.ai-48-hours {
    // background-image: url('/lib/images/svgs/48-hours.svg');
    height: 88px;
    width: 88px;
}
.ai-about-you {
    background-image: url("data:image/svg+xml, #{$ai-about-you}");
    height: 80px;
    width: 80px;
}
.ai-add-account {
    background-image: url("data:image/svg+xml, #{$ai-add-account}");
    height: 60px;
    width: 60px;
}
.ai-air-moving {
    // background-image: url('/lib/images/svgs/air-moving.svg');
    height: 80px;
    width: 80px;
}
.ai-alert-triangle {
    background-image: url("data:image/svg+xml, #{$ai-alert-triangle}");
    height: 28px;
    width: 35px;
}
.ai-arrows-all-directions {
    background-image: url("data:image/svg+xml, #{$ai-arrows-all-directions}");
    height:49px;
    width:60px;
}
.ai-arrows-east-west-o {
    background-image: url("data:image/svg+xml, #{$ai-arrows-east-west-o}");
    height: 63px;
    width: 63px;
}
.ai-awkward-face {
    background-image: url("data:image/svg+xml, #{$ai-awkward-face}");
    height: 27px;
    width: 27px;
}
.ai-bar-graph-up {
    background-image: url("data:image/svg+xml, #{$ai-bar-graph-up}");
    height: 80px;
    width: 80px;
}
.ai-bolt {
    background-image: url("data:image/svg+xml, #{$ai-bolt}");
    height: 35px;
    width: 25px;
}
.ai-btn-close {
    background-image: url("data:image/svg+xml, #{$ai-btn-close}");
    height: 40px;
    width: 40px;
}
.ai-budget-spreadsheet {
    background-image: url("data:image/svg+xml, #{$ai-budget-spreadsheet}");
    height: 60px;
    width: 60px;
}
.ai-calculator {
    // background-image: url('/lib/images/svgs/calculator.svg');
    height: 80px;
    width: 65px;
}
.ai-calendar-flip {
    background-image: url("data:image/svg+xml, #{$ai-calendar-flip}");
    height: 56px;
    width: 56px;
}
.ai-calendar-o{
    background-image: url("data:image/svg+xml, #{$ai-calendar-o}");
    height: 48px;
    width: 48px;
}
.ai-callout-quotes-dollar-sign {
    // background-image: url('/lib/images/svgs/callout-quotes-dollar-sign.svg');
    height: 71px;
    width: 80px;
}
.ai-candle {
    background-image: url("data:image/svg+xml, #{$ai-candle}");
    height: 60px;
    width: 60px;
}
.ai-cell-phone-loading-dots {
    background-image: url("data:image/svg+xml, #{$ai-cell-phone-loading-dots}");
    height: 60px;
    width: 60px;
}
.ai-cell-phone-text-bubble {
    // background-image: url('/lib/images/svgs/cell-phone-text-bubble.svg');
    height: 76px;
    width: 80px;
}
.ai-central-air{
    // background-image: url('/lib/images/svgs/central-air.svg');
    height:80px;
    width:72px;
}
.ai-chain-link {
    // background-image: url('/lib/images/svgs/chain-link.svg');
    height: 71px;
    width: 80px;
}
.ai-chart {
    // background-image: url('/lib/images/svgs/chart-icon.svg');
    background-position-y: -3px;
    height: 34px;
    width: 32px;
}

.ai-checkmark {
    background-image: url("data:image/svg+xml, #{$ai-checkmark}");
    height: 30px;
    width: 30px;
}
.ai-check-your-email {
    background-image: url("data:image/svg+xml, #{$ai-check-your-email}");
    height: 80px;
    width: 80px;
}
.ai-chevron-arrow-left-blue {
    // background-image: url('/lib/images/svgs/chevron-arrow-left-blue.svg');
    height: 11px;
    width: 17px;
}
.ai-chevron-right-o {
    background-image: url("data:image/svg+xml, #{$ai-chevron-right-o}");
    height:63px;
    width:63px;
}
.ai-circle-switchboard {
    // background-image: url('/lib/images/svgs/circle-switchboard.svg');
    height: 80px;
    width: 80px;
}
.ai-clipboard {
    background-image: url("data:image/svg+xml, #{$ai-clipboard}");
    height: 84px;
    width:61px;
}
.ai-clock {
    background-image: url("data:image/svg+xml, #{$ai-clock}");
    height: 35px;
    width: 35px;
}
.ai-coal-fuel {
    background-image: url("data:image/svg+xml, #{$ai-coal-fuel}");
    height: 80px;
    width: 80px;
}
.ai-coins {
    // background-image: url('/lib/images/svgs/coins.svg');
    height: 53px;
    width: 59px;
}
.ai-computer-envelope {
    background-image: url("data:image/svg+xml, #{$ai-computer-envelope}");
    height: 66px;
    width: 80px;
}
.ai-computer-help {
    // background-image: url('/lib/images/svgs/computer-help.svg');
    height: 50px;
    width: 60px;
}
.ai-computer-money-lightbulb {
    // background-image: url('/lib/images/svgs/computer-money-lightbulb.svg');
    height: 80px;
    width: 80px;
}
.ai-confirm-access {
    background-image: url("data:image/svg+xml, #{$ai-confirm-access}");
    height: 60px;
    width: 60px;
}
.ai-confirmation-code {
    background-image: url("data:image/svg+xml, #{$ai-confirmation-code}");
    height: 80px;
    width: 80px;
}
.ai-credit-card-circle {
    background-image: url("data:image/svg+xml, #{$ai-credit-card-circle}");
    height: 92px;
    width: 92px;
}
.ai-customer {
    background-image: url("data:image/svg+xml, #{$ai-customer}");
    height: 80px;
    width: 80px;

}
.ai-desktop-monitor {
    // background-image: url('/lib/images/svgs/desktop-monitor.svg');
    height: 60px;
    width: 60px;
}
.ai-disconnected {
    background-image: url("data:image/svg+xml, #{$ai-disconnected}");
    height: 60px;
    width: 60px;
}
.ai-doc {
    background-image: url("data:image/svg+xml, #{$ai-doc}");
}
.ai-document-circle {
    background-image: url("data:image/svg+xml, #{$ai-document-circle}");
    height: 92px;
    width: 92px;
}
.ai-dollar {
    // background-image: url('/lib/images/svgs/dollar-icon.svg');
    height: 35px;
    width: 35px;
}
.ai-emission-free {
    background-image: url("data:image/svg+xml, #{$ai-emission-free}");
    height: 80px;
    width: 80px;
}
.ai-enter-new-email {
    background-image: url("data:image/svg+xml, #{$ai-enter-new-email}");
    height: 80px;
    width: 80px;
}
.ai-envelope {
    background-image: url("data:image/svg+xml, #{$ai-envelope}");
    height:39px;
    width:55px;
}
.ai-envelope-detailed {
    background-image: url("data:image/svg+xml, #{$ai-envelope-detailed}");
    height: 35px;
    width: 50px;
}
.ai-error {
    // background-image: url("/lib/images/svgs/default-error-icon.svg");
    height: 30px;
    width: 30px;
}
.ai-excel {
    background-image: url("data:image/svg+xml, #{$ai-excel}");
}
.ai-find-account {
    background-image: url("data:image/svg+xml, #{$ai-find-account}");
    height: 80px;
    width: 80px;
}
.ai-follow-email-instructions {
    background-image: url("data:image/svg+xml, #{$ai-follow-email-instructions}");
    height: 80px;
    width: 80px;
}
.ai-free-app {
    background-image: url("data:image/svg+xml, #{$ai-free-app}");
}
.ai-hand-apple {
    // background-image: url('/lib/images/svgs/hand-apple.svg');
    height: 80px;
    width: 79px;
}
.ai-heart-o{
    background-image: url("data:image/svg+xml, #{$ai-heart-o}");
    height: 48px;
    width: 48px;
}
.ai-hi-bubble {
    background-image: url("data:image/svg+xml, #{$ai-hi-bubble}");
    height: 36px;
    width: 35px;
}
.ai-holding-hands {
    background-image: url("data:image/svg+xml, #{$ai-holding-hands}");
    height: 60px;
    width: 60px;
}
.ai-house-circle {
    background-image: url("data:image/svg+xml, #{$ai-house-circle}");
    height: 92px;
    width: 92px;
}
.ai-humidity {
    // background-image: url('/lib/images/svgs/humidity.svg');
    height: 80px;
    width: 80px;
}
.ai-icicles {
    background-image: url("data:image/svg+xml, #{$ai-icicles}");
    height: 60px;
    width: 60px;
}
.ai-info-icon {
    background-image: url("data:image/svg+xml, #{$ai-info-icon}");
    height: 30px;
    width: 30px;
    min-width:30px;
}
.ai-information {
    background-image: url("data:image/svg+xml, #{$ai-information}");
    height: 60px;
    width: 60px;
}
.ai-knit-hat {
    background-image: url("data:image/svg+xml, #{$ai-knit-hat}");
    height: 60px;
    width: 60px;
}
.ai-lightbulb {
    background-image: url("data:image/svg+xml, #{$ai-lightbulb}");
    height: 80px;
    width: 71px;
}
.ai-lightbulb-green {
    background-image: url("data:image/svg+xml, #{$ai-lightbulb-green}");
    height: 80px;
    width: 71px;
}
.ai-lightning{
    background-image: url("data:image/svg+xml, #{$ai-lightning}");
    background-repeat:no-repeat;
    height:70px;
    width:42px;
}
.ai-lightning-circle {
    background-image: url("data:image/svg+xml, #{$ai-lightning-circle}");
    height: 92px;
    width: 92px;
}
.ai-location {
    background-image: url("data:image/svg+xml, #{$ai-location}");
    height: 60px;
    width: 60px;
}
.ai-medical-clipboard {
    background-image: url("data:image/svg+xml, #{$ai-medical-clipboard}");
    height: 60px;
    width: 60px;
}
.ai-mittens {
    background-image: url("data:image/svg+xml, #{$ai-mittens}");
    background-repeat: no-repeat;
    height: 70px;
    width: 42px;
}
.ai-modal-toggle {
    // background-image: url('/lib/images/svgs/modal-zoom.svg');
    background-size: 45px;
    bottom: 20px;
    cursor: pointer;
    display: none;
    height: 45px;
    position: absolute;
    right: 20px;
    width: 45px;
}
.ai-money-circle {
    background-image: url("data:image/svg+xml, #{$ai-money-circle}");
    height: 80px;
    width: 80px;
}
.ai-more-info {
    background-image: url("data:image/svg+xml, #{$ai-more-info}");
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    display: inline-block;
}
.ai-natural-gas {
    background-image: url("data:image/svg+xml, #{$ai-natural-gas}");
    height: 80px;
    width: 80px;
}
.ai-open {
    // background-image: url('/lib/images/svgs/open-icon.svg');
    background-position-y: -2px;
    height: 59px;
    width: 66px;
}
.ai-person {
    // background-image: url('/lib/images/svgs/person-icon.svg');
    height: 31px;
    width: 28px;
}
.ai-phone-message {
    background-image: url("data:image/svg+xml, #{$ai-phone-message}");
    height: 34px;
    width: 22px;
}
.ai-pin {
    background-image: url("data:image/svg+xml, #{$ai-pin}");
}

.ai-pipp {
    background-image: url("data:image/svg+xml, #{$ai-pipp}");
    height: 60px;
    width: 60px;
}
.ai-moving-truck {
    background-image: url("data:image/svg+xml, #{$ai-moving-truck}");
    height: 80px;
    width: 80px;
}
.ai-reduce-energy {
    // background-image: url("/lib/images/svgs/reduce-energy.svg");
    height: 80px;
    width: 80px;
}
.ai-report-o{
    background-image: url("data:image/svg+xml, #{$ai-report-o}");
    height: 48px;
    width: 48px;
}
.ai-review-address {
    background-image: url("data:image/svg+xml, #{$ai-review-address}");
    height: 80px;
    width: 80px;
}
.ai-sad-face {
    background-image: url("data:image/svg+xml, #{$ai-sad-face}");
    height: 27px;
    width: 27px;
}
.ai-scam {
    background-image: url("data:image/svg+xml, #{$ai-scam}");
    height: 34px;
    width: 32px;
}
.ai-solar-panel {
    background-image: url("data:image/svg+xml, #{$ai-solar-panel}");
    height: 80px;
    width: 80px;
}
.ai-spray-bottle {
    // background-image: url('/lib/images/svgs/spray-bottle.svg');
    height: 79px;
    width: 79px;
}
.ai-stop-o {
    background-image: url("data:image/svg+xml, #{$ai-stop-o}");
    height: 63px;
    width: 63px;
}
.ai-storm {
    background-image: url("data:image/svg+xml, #{$ai-storm}");
    height: 60px;
    width: 60px;
}
.ai-step-number {
    background-image: url("data:image/svg+xml, #{$ai-step-number}");
    height: 30px;
    width: 30px;
}
.ai-step-number-selected {
    background-image: url("data:image/svg+xml, #{$ai-step-number-selected}");
    height: 44px;
    width: 44px;
}
.ai-success-check {
    background-image: url('/lib/images/svgs/ICON_circle-check_green.svg');
    height: 30px;
    width: 30px;
}
.ai-support-monitor {
    background-image: url("data:image/svg+xml, #{$ai-support-monitor}");
    height: 60px;
    width: 60px;
}
.ai-texas-outline {
    // background-image: url('/lib/images/svgs/texas-outline.svg');
    height: 51px;
    width: 61px;
}
.ai-thermometer {
    // background-image: url('/lib/images/svgs/thermometer.svg');
    height: 80px;
    width: 71px;
}
.ai-thermostat {
    // background-image: url('/lib/images/svgs/thermostat.svg');
    height: 80px;
    width: 80px;
}
.ai-text-alert-box {
    background-image: url("data:image/svg+xml, #{$ai-text-alert-box}");
    height: 35px;
    width: 50px;
}
.ai-tools-x {
    background-image: url("data:image/svg+xml, #{$ai-tools-x}");
    height:54px;
    width:54px;
}
.ai-trees {
    // background-image: url('/lib/images/svgs/trees.svg');
    height: 60px;
    width: 76px;
}
.ai-triangle-alert {
    // background-image: url('/lib/images/svgs/triangle-alert.svg');
    height: 58px;
}
.ai-user-o{
    background-image: url("data:image/svg+xml, #{$ai-user-o}");
    height: 48px;
    width: 48px;
}
.ai-verify-account-access {
    background-image: url("data:image/svg+xml, #{$ai-verify-account-access}");
    height: 80px;
    width: 80px;
}
.ai-verify-your-email {
    background-image: url("data:image/svg+xml, #{$ai-verify-your-email}");
    height: 80px;
    width: 80px;
}
.ai-verify-address {
    background-image: url("data:image/svg+xml, #{$ai-verify-address}");
    height: 60px;
    width: 60px;
}
.ai-virginia-energy {
    background-image: url("data:image/svg+xml, #{$ai-virginia-energy}");
    height: 60px;
    width: 60px;
}
.ai-wallet {
    // background-image: url('/lib/images/svgs/wallet.svg');
    height: 60px;
    width: 60px;
}
.ai-warning-circle {
    background-image: url("data:image/svg+xml, #{$ai-warning-circle}");
    height: 30px;
    width: 30px;
}
.ai-water-heater {
    // background-image: url('/lib/images/svgs/water-heater.svg');
    height: 80px;
    width: 49px;
}
.ai-weatherization {
    background-image: url("data:image/svg+xml, #{$ai-weatherization}");
    height: 60px;
    width: 60px;
}
.ai-wind-turbine {
    background-image: url("data:image/svg+xml, #{$ai-wind-turbine}");
    height: 80px;
    width: 80px;
}
.ai-winter-house {
    background-image: url("data:image/svg+xml, #{$ai-winter-house}");
    height: 60px;
    width: 60px;
}
.ai-zip {
    background-image: url("data:image/svg+xml, #{$ai-zip}");
}

