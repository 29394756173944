﻿
//----------------------------------------------
// HEADER
//----------------------------------------------
#globalLoginMenu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;

    .ai-more-info.ai-small {
        height: 24px;
        width: 24px;
        background-size: 24px 24px;
    }

    .btn.btn-secondary-white {
        font-size: 1.125rem;
        line-height: 50px;
        font-weight: $font-weight-bold;
    }

    @media all and (min-width:map-get($grid-breakpoints, "sm")) {
        top: 45px;
        right: 50px;
        left: auto;
        width: auto;
        max-width: 500px;
    }

    @media all and (min-width:map-get($grid-breakpoints, "md")) {
    }

    @media all and (min-width:map-get($grid-breakpoints, "lg")) {
        top: 60px;

        p, a, .checkbox label, .error, .text-forgot {
            font-size: 1.125rem;
        }

        h3 {
            font-size: 1.875rem;
        }

        .btn.btn-secondary-white {
            font-size: 1.25rem;
            line-height: 58px;
        }
    }

    @media all and (min-width:map-get($grid-breakpoints, "xl")) {
    }

    .form-group.aep-custom {
        /*margin-bottom: 0;*/
    }

    #DivRowPassword .form-group.aep-custom {
        margin-bottom: 0;
    }
}

#globalLoginMenu .error-block {
    background-color: #de3600;
    color: white;
    padding: 20px 30px 20px;

    @media all and (min-width: map-get($grid-breakpoints, "lg") ) {
        padding: 20px 45px 30px;
    }
}

.off-canvas-overlay.login-visible {
    background-color: transparent;
    display: block;
}

