﻿//----------------------------------------------
// CAROUSEL
//----------------------------------------------

// Text Only  ----------------------------------
.carousel {
    background-color: $light-gray;
    margin: 0 -30px;
    height: 250px;
    max-height: 250px;
}

.carousel-paragraph-above {
    margin-top: calc(30px - .75em);
}

.carousel-h1-above, .carousel-h2-above {
    margin-top: calc(30px - .25em);
}

.carousel-inner {
    padding: 34px 45px;
}

.carousel-item {
    backface-visibility: unset;

    p {
        font-size: 24px;
        line-height: 1.33;
        text-align: center;
    }
}

.carousel-indicators {
    bottom: 34px;
    margin: 0 auto;

    li {
        background-color: $icon-gray;
        height: 14px;
        width: 14px;
        margin-left: 8px;
        margin-right: 9px;
        border-radius: 50%;
        background-clip: unset;
        border: none;
        opacity: 1;

        &:hover {
            opacity: .6;
        }
    }
}

.carousel-indicators .active {
    background-color: $dark-blue;

    &:hover {
        opacity: 1;
    }
}


// tablet small (iPad Mini)
@media all and (min-width:map-get($grid-breakpoints, "sm")) {
    .carousel {
        margin-left:0;
        margin-right:0;
        height: 200px;
        max-height: 200px;
    }

    .carousel-inner {
        padding: 34px 62px;
    }

    .carousel-item p {
        font-size: 26px;
        line-height: 1.38;
    }

    .carousel-indicators li {
        height: 14px;
        width: 14px;
    }
}

// tablet large (IPad Pro)
@media all and (min-width:map-get($grid-breakpoints, "md")) {
    .carousel {
        height: 250px;
        max-height: 250px;
    }

    .carousel-paragraph-above {
        margin-top: calc(75px - .75em);
    }

    .carousel-h1-above {
        margin-top: calc(75px - .25em);
    }

    .carousel-inner {
        padding: 44px 62px;
    }

    .carousel-indicators li {
        height: 16px;
        width: 16px;
    }
}

// desktop
@media all and (min-width: map-get($grid-breakpoints, "lg")) {
    .carousel-paragraph-above {
        margin-top: calc(100px - .75em);
    }

    .carousel-h1-above {
        margin-top: calc(100px - .25em);
    }
    .carousel-inner {
        padding: 54px 74px;
    }

    .carousel-item p {
        font-size: 36px;
        line-height: 1.24;
    }

    .carousel-indicators li {
        height: 18px;
        width: 18px;
    }
}

// xxs - this is just in case
@media all and (max-width: 363px) {
    .carousel-inner {
        padding: 34px 25px;
    }

    .carousel-item p {
        font-size: 22px;
    }
}
// End Text Only  ------------------------------
