﻿/* variables used throughout our scss AND bootstrap variables */

//----------------------------------------------
// MODAL VARS
//----------------------------------------------

// sizes are 100px less than specified due to padding for close button
$modal-xl: 1240px;
$modal-lg: 1000px;
$modal-md: 800px;
$modal-sm: 500px;

// Bootstrap overrides
// Padding applied to the modal body
$modal-inner-padding: 2rem !default;

// Zero margin so modal extends width of screen
$modal-dialog-margin: 0 !default;

// Lowering margin-y on modal for small and up
$modal-dialog-margin-y-sm-up: 0.5rem !default;