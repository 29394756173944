﻿
//----------------------------------------------
// FOOTER
//----------------------------------------------
.footer-nav {
    margin: 30px 0 0 0;

    @media (min-width: map-get($grid-breakpoints, "lg") ) {
        margin: 100px 0 0 0;
    }

    .background-red {
        @media (min-width: map-get($grid-breakpoints, "md") ) {
            background-color: $red;
        }

        .container {
            @media (max-width: map-get($grid-breakpoints, "md") ) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.footer {
    font-size: 1.375rem;
    list-style-type: none;
    margin: 0 0 35px 0;
    max-width: 100%;
    padding: 0;

    @media (min-width: map-get($grid-breakpoints, "md") ) {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.125rem;
        justify-content: space-between;
        margin: 0 0 20px 0;
        padding: 0 60px;
    }

    @media (min-width: map-get($grid-breakpoints, "lg") ) {
        font-size: 1.25rem;
        margin: 0 0 30px 0;
        padding: 0 60px;
    }

    @media (min-width: map-get($grid-breakpoints, "xl") ) {
        font-size: 1.775rem;
        margin: 0 0 40px 0;
        padding: 0 90px;
    }

    li {
        margin: 0 0 5px 0;
        text-align: center;

        @media (min-width: map-get($grid-breakpoints, "md") ) {
            padding: 14px 0;
            margin: 0;
            text-align: left;
        }

        @media (min-width: map-get($grid-breakpoints, "lg") ) and (max-width: map-get($grid-breakpoints, "xl") ) {
            padding: 20px 0;
        }

        @media (min-width: map-get($grid-breakpoints, "xl") ) {
            padding: 19px 0;
        }
    }

    a {
        background-color: $red;
        color: $white;
        display: block;
        font-weight: $font-weight-bold;
        padding: 14px 0;
        text-transform: uppercase;

        &:hover {
            text-decoration-color: $white;
        }

        @media (min-width: map-get($grid-breakpoints, "md") ) {
            background-color: transparent;
            display: inline-block;
            margin: 0;
            padding: 0;
        }
    }
}

.sub-footer {
    .footer-links {
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            text-align: left;
        }

        a {
            color: $dark-gray;
            font-size: .875rem;
            font-weight: $font-weight-bolder;
            padding: 0 10px;

            &:first-child {
                padding: 0 10px 0 0;
            }

            &:last-child {
                padding: 0 0 0 10px;
            }

            &:hover {
                text-decoration-color: $dark-gray;
            }

            @media (min-width: map-get($grid-breakpoints, "lg") ) {
                font-size: 1rem;
                margin: 0 15px;
                padding: 0;

                &:first-child {
                    margin: 0 15px 0 0;
                    padding: 0;
                }

                &:last-child {
                    padding: 0;
                }
            }
        }
    }

    .info-callout {
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, "lg") ) and (max-width: map-get($grid-breakpoints, "xl") ) {
            font-size: 1.375rem;
        }

        @media (min-width: map-get($grid-breakpoints, "xl") ) {
            font-size: 1.875rem;
        }
    }

    .document-icon {
        background-position: left 1px;
        background-size: 22px 22px;
        font-size: 1rem;
        padding: 0 0 0 36px;
        text-transform: uppercase;
        max-width: 300px;

        @media (min-width: map-get($grid-breakpoints, "md") ) and (max-width: map-get($grid-breakpoints, "lg") ) {
            background-position: left 2px;
            background-size: 21px 21px;
        }

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            background-position: left 0px;
            padding: 2px 0 2px 36px;
        }
    }

    .footer-pipe {
        display: inline;
        font-size: 1rem;
        font-weight: $font-weight-bolder;
    }

    .disclaimer {
        font-size: .75rem;
        margin-bottom: 25px;

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            font-size: .875rem;
            margin-bottom: 40px;
        }

        a {
            color: $red;
            font-weight: $font-weight-bolder;

            &:hover {
                text-decoration-color: $red;
            }
        }

        span {
            display: block;
            text-align: center;

            @media (min-width: map-get($grid-breakpoints, "lg") ) {
                text-align: left;
            }
        }
    }

    .social-media {
        margin: 0 0 35px 0;

        @media (min-width: map-get($grid-breakpoints, "lg") ) {
            margin: 0;
        }

        a {
            background-position: center center;
            background-repeat: no-repeat;
            display: block;
            margin: 0 30px 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    .twitter {
        // background-image: url('/lib/images/svgs/twitter.svg');
        height: 41px;
        width: 25px;
    }

    .facebook {
        // background-image: url('/lib/images/svgs/fb.svg');
        height: 37px;
        width: 12px;
    }

    .linkedin {
        // background-image: url('/lib/images/svgs/linked-in.svg');
        height: 37px;
        width: 27px;
    }

    .youtube {
        // background-image: url('/lib/images/svgs/youtube.svg');
        background-size: 35px 23px;
        height: 37px;
        width: 35px;
    }

    .instagram {
        // background-image: url('/lib/images/svgs/instagram-icon.svg');
        background-size: 30px 30px;
        height: 42px;
        width: 30px;
    }
}