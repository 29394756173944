.acct-summary .nav-tabs .nav-link {
    border-top: 8px solid #E4F4FD;
    border-right: 8px solid #E4F4FD;
    border-bottom: 8px solid #E4F4FD;
    border-left: 8px solid #E4F4FD;
    font-size: 1.375rem;

    &:hover {
        border-color: #E4F4FD;
        text-decoration: underline;
    }

    @media all and (min-width: 768px) {
        border-right: 4px solid #E4F4FD;
        border-bottom: none;
        border-left: 4px solid #E4F4FD;
    }

    @media all and (min-width: 1024px) {
        border-right: 6px solid #E4F4FD;
        border-bottom: none;
        border-left: 6px solid #E4F4FD;
    }
}

.acct-summary .nav-tabs .nav-item {
    margin: 0 !important;
    border: 0 !important;

    @media all and (min-width: 768px) {
        &:first-child {
            .nav-link {
                border-left: 8px solid #E4F4FD;
            }
        }

        &:last-child {
            .nav-link {
                border-right: 8px solid #E4F4FD;
            }
        }
    }

    @media all and (min-width: 1024px) {
        &:first-child {
            .nav-link {
                border-left: 12px solid #E4F4FD;
            }
        }

        &:last-child {
            .nav-link {
                border-right: 12px solid #E4F4FD;
            }
        }
    }
}

.nav-link {
    background-color: white;
}

.btn {
    margin: 0;
}